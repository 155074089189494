import React from "react";
import ModalSignUpBody from "../components/modal-sign-up.component";
import ModalSignInBody from "../components/modal-sign-in.component";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../redux/user/user.selector";

const Login = ({ currentUser }) => {
  const [signUpSignIn, setSignUpSignIn] = React.useState(1);
  
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <section className="login-page">
      <Container className="narrow">
        <Row className="justify-content-center flex-column">
          <Col>
            <h1 className="header-title mb-1">
              Sign {signUpSignIn === 1 ? "Up" : "In"}
            </h1>
            {signUpSignIn === 1 ? (
              <small>
                Already have an account? &nbsp;
                <strong
                  className="text-primary pointer-cursor"
                  onClick={() => setSignUpSignIn(2)}
                >
                  Sign In here.
                </strong>
              </small>
            ) : (
              <small>
                Don't have an account? &nbsp;
                <strong
                  className="text-primary pointer-cursor"
                  onClick={() => setSignUpSignIn(1)}
                >
                  Sign Up here.
                </strong>
              </small>
            )}
            <div className="mt-4"></div>
            {signUpSignIn === 1 ? <ModalSignUpBody /> : <ModalSignInBody />}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Login);

import React from "react";
import { Row, Container, Col, Image } from "react-bootstrap";

const AboutStory = () => (
  <section className="about-story">
    <Container>
      <Row>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <Image
            className="img-fluid align-self-center mb-3"
            src={require("../assets/dffboys.png")}
            height="200"
          ></Image>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-start flex-column"
        >
          <h1>Daily Fresh Fruits</h1>
          <p>
            Stay at home safely with active lifestyle: Enjoy our fresh cut
            fruits that deliver to your doorstep every day to boost your health
            easily.
          </p>
          <p>
            安全的宅在家一样可以享受健康的生活：
            每日不同的新鲜水果送到你家，让你轻松维护身体健康。
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-start flex-column"
        >
          <p>
            Natural fresh fruits is essential to everyday life with Vitamins and
            Minerals, and they are high in Fiber. Fruits also provide a wide
            range of health-boosting antioxidants and nutrients, including
            flavonoids and potassium, which suitable for all family members.
          </p>
          <p>
            Eating more fruits in place of higher calorie foods can reduce a
            person's risk of developing heart disease, cancer, inflammation, and
            diabetes and improve overall body immunity.
          </p>
          <p>
            新鲜水果是我们日常生活中必不可少的维生素和矿物质来源，且富含纤维素。
            同时，水果还能提供大量促进人体健康的抗氧化物质和多种营养物质，包括叶酸和钾等，全家老少适宜。
          </p>
          <p>
            多吃水果来代替高热量的食物可以减少人患心脏病，癌症，炎症和糖尿病的风险，提高身体免疫力。{" "}
          </p>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <Image
            className="img-fluid align-self-center mb-3"
            src={require("../assets/thumbnail.png")}
            width="100%"
          ></Image>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <Image
            className="img-fluid align-self-center mb-3"
            src={require("../assets/dff_happy_staff.png")}
            height="200"
          ></Image>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-start flex-column"
        >
          <p>
            Our daily routine is like selecting imported fruits, cleaning and
            prepare fresh cut fruits according to the daily order.
          </p>
          <p>
            We do not do “pre–packed” as you know all cut fruits cannot be
            stored, we only prepared your fruits after daily “cut-off time of
            order”, then we will prepare and deliver to you on the same day.
          </p>
          <p>
            Our fruits are imported from Malaysia, Thailand, Indonesia and
            Australia , wide range to choose like: apple, guava, kiwi, mango,
            watermelon, orange, dragon fruits & etc.
          </p>
          <p>
            我们每天将会定时从各地进口的新鲜水果中挑选出优质成熟的水果，洗净并且切好，按顾客的要求完成分装并派送。
          </p>
          <p>
            为了保证新鲜，我们不会提前分装，只会在每日订单截止时间后才对水果进行处理，切好后直接分装，当日派送。
          </p>
          <p>
            我们的水果来自马来西亚，泰国，印尼以及澳洲。
            种类包括苹果，橙，芒果，西瓜，黄梨，猕猴桃，火龙果，哈密瓜，等等
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

export default AboutStory;

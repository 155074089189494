import { createSelector } from "reselect";

const selectFruits = (state) => state.fruits;

export const selectFruitsItems = createSelector(
  [selectFruits],
  (fruit) => fruit.fruitItems
);

export const selectFruitsOfTheDay = createSelector(
  [selectFruitsItems],
  (FruitsOfTheDay) =>
    FruitsOfTheDay
      ? FruitsOfTheDay.filter((fruit) => fruit.fruit_of_the_day === 1).splice(
          0,
          2
        )
      : null
);

export const selectFruitsRecommended = createSelector(
  [selectFruitsItems],
  (fruitsRecommended) =>
    fruitsRecommended
      ? fruitsRecommended
          .filter((fruit) => fruit.recommended === 1)
          .splice(0, 4)
      : null
);

export const selectStoreStatus = createSelector(
  [selectFruits],
  (status) => status.isStoreOpen
);

export const selectFruitSlideModalStatus = createSelector(
  [selectFruits],
  (status) => status.fruitSlideModalShow
);

export const selectFruitSlideModalImages = createSelector(
  [selectFruits],
  (images) => images.fruitSlideImage
);

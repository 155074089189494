import CartActionTypes from "./cart.types";
import {
  addItemToCart,
  removeItemFromCart,
  setPriceFromCart,
  addOneItem,
  removeOneItem
} from "./cart.utils";

const INITIAL_STATE = {
  cartItems: [],
  price: {
    total: 0,
    canPay: false,
    canAdd: true,
  },
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.CART_ADD:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload.item),
      };
    case CartActionTypes.CART_DELETE:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload.item),
      };
    case CartActionTypes.SET_PRICE:
      return {
        ...state,
        price: setPriceFromCart(state.cartItems),
      };
    case CartActionTypes.CART_INCREMENT:
      return {
        ...state,
        cartItems: addOneItem(state.cartItems, action.payload),
      };
    case CartActionTypes.CART_DECREMENT:
      return {
        ...state,
        cartItems: removeOneItem(state.cartItems, action.payload),
      };
    case CartActionTypes.WIPE_CART:
      return {
        ...state,
        cartItems: [],
        price: {
          total: 0,
          canPay: false,
          canAdd: true,
        },
      };
    default:
      return state;
  }
};

export default cartReducer;

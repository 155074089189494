import React, { useEffect, useState } from "react";
import { Image, Badge } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";

import {
  selectUserAddress,
  selectUserBillingAddress,
  selectCurrentUserData,
} from "../redux/user/user.selector";
import { selectCartItems, selectPrice } from "../redux/cart/cart.selector";
import { wipeCart } from "../redux/cart/cart.action";
import { setToken } from "../redux/user/user.action";

import { refreshToken, finalizePayment } from "../functions/user.functions";

const AlipayLoading = ({
  user,
  setToken,
  wipeCart,
  cart,
  userAddress,
  userBillingAddress,
  price,
  history,
}) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    if (stripe) {
      const pollForSourceStatus = async () => {
        const queryString = window.location.search;
        // console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const CLIENT_SECRET = urlParams.get("client_secret");
        const SOURCE_ID = urlParams.get("source");

        let pollCount = 0;
        const MAX_POLL_COUNT = 900; // untuk nunggu pembayaran selama 15 menit

        // bakal run ini untuk verify pembayaran
        const result = await stripe.retrieveSource({
          id: SOURCE_ID,
          client_secret: CLIENT_SECRET,
        });
        let source = result.source;
        // console.log("source", result);
        if (source.status === "chargeable") {
          // console.log("charging");
          const token = await refreshToken(user.uid, setToken);
          await finalizePayment(
            false,
            SOURCE_ID,
            cart,
            userAddress,
            userBillingAddress,
            price.total,
            0,
            token,
            "alipay"
          );

          // console.log(response);

          await wipeCart();
          history.push("/thankyou");

          // Make a request to your server to charge the Source.
          // Depending on the Charge status, show your customer the relevant message.
        } else if (source.status === "pending" && pollCount < MAX_POLL_COUNT) {
          // Try again in a second, if the Source is still `pending`:
          pollCount += 1;
          // console.log("pending");
          return setTimeout(pollForSourceStatus, 1000);
        } else {
          setErrorMsg(true);
          // console.log("faiPled");
        }
      };
      pollForSourceStatus();
    }
  }, [stripe]);
  return (
    <div className="alipay-loading flex-column">
      <Image
        className={`mb-5 ${errorMsg ? "error" : "loading"}`}
        src={require("../assets/logo512.png")}
        height="128"
      ></Image>
      {errorMsg ? (
        <span className={`text-danger ${!errorMsg ? "d-none" : ""} mt-2`}>
          Payment failed, click{" "}
          <Link to="/checkout">
            <Badge variant="primary">here</Badge>
          </Link>{" "}
          to try again, or to try another payment.
        </span>
      ) : (
        <span>Please wait while your payment is being processed</span>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cart: selectCartItems,
  user: selectCurrentUserData,
  userAddress: selectUserAddress,
  userBillingAddress: selectUserBillingAddress,
  price: selectPrice,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  wipeCart: () => dispatch(wipeCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlipayLoading);

import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Button, Image, Spinner } from "react-bootstrap";

const AlipayButton = ({ totalPrice }) => {
  const [isLoading, setLoading] = useState(false);
  const stripe = useStripe();
  const handlePayment = async () => {
    setLoading(true);
    await stripe
      .createSource({
        type: "alipay",
        amount: totalPrice * 100,
        currency: "sgd",
        redirect: {
          return_url: process.env.REACT_APP_BASE_URL + "alipay-process",
        },
      })
      .then(function (result) {
        // console.log("res", result);
        window.location = result.source.redirect.url;
      });
  };

  return (
    <Button
      size={"lg"}
      disabled={isLoading}
      block
      variant="google"
      onClick={isLoading ? undefined : handlePayment}
    >
      {isLoading ? (
        <React.Fragment>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          <span className="sr-only">Loading...</span>
        </React.Fragment>
      ) : (
        ""
      )}
      <Image
        className="mr-3"
        src={require("../assets/alipay-logo-lg.png")}
        height="20"
      ></Image>
      Pay ${totalPrice} with Alipay
    </Button>
  );
};

export default AlipayButton;

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  FormControl,
  Spinner,
  Form,
  Badge,
  Alert,
} from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";

import {
  selectCurrentUserData,
  selectCurrentUserPassword,
} from "../redux/user/user.selector";
import { setUserData, setToken, setPassword } from "../redux/user/user.action";

import {
  refreshUserData,
  refreshToken,
  updateUserData,
} from "../functions/user.functions";

import CustomButton from "./custom-button.component";

const ProfileEditor = ({
  title,
  content,
  email,
  setUser,
  obj,
  setToken,
  user,
  password,
  passwordEdit,
  setPassword,
  phoneEdit,
}) => {
  const [contentVal, setContentVal] = useState(content ? content : "");
  const [editMode, toggleEditMode] = useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [peekPassword, setPeekPassword] = useState(
    "Haven't Entered Any Password Yet"
  );
  const [peekPasswordCont, togglePeekPasswordCont] = useState(false);

  const handlePasswordToggle = () => {
    togglePeekPasswordCont(!peekPasswordCont);
  };
  React.useEffect(() => {
    if (phoneEdit) {
      if (content === null) {
        setContentVal("+65");
      }
    }
    if (passwordEdit) {
      setContentVal("");
      setOldPassword("");
    }
  }, []);

  React.useEffect(() => {
    if (peekPassword === "") {
      setPeekPassword("You Haven't Enterend Any Password");
    }
  }, [peekPassword]);

  useEffect(() => {
    setErrorMessage(null);
  }, [editMode]);

  const handleOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const handleVerify = async () => {
    setSuccessMessage(null);
    toggleLoading(true);

    const token = await refreshToken(user.uid, setToken);

    await fetch(process.env.REACT_APP_API_URL + "user/sendemailverification", {
      method: "POST",
      headers: {
        token: token,
      },
    }).then((response) => {
      if (response.status === 500) {
        setErrorMessage(
          "There's an unexpected error or you may have requested the verification too many times. Please try again later."
        );
        toggleLoading(false);
      } else {
        setShowEmailAlert(true);
        toggleLoading(false);
      }
    });
  };

  const saveEdit = async (e) => {
    e.preventDefault();
    if (contentVal === "") {
      setErrorMessage("Please fill the field.");
      return;
    }
    setSuccessMessage(null);
    setErrorMessage(null);
    toggleEditMode(!editMode);
    toggleLoading(true);

    if (passwordEdit) {
      if (password !== oldPassword) {
        setErrorMessage("Wrong old password");
        toggleLoading(false);
        return;
      }
    }

    const token = await refreshToken(user.uid, setToken);

    const response = await updateUserData(obj, token, contentVal, password);

    if (passwordEdit) {
      setPassword(contentVal);
      setContentVal("");
      setOldPassword("");
    }

    if (response.status_code === 400) {
      setErrorMessage(response.message);
      toggleLoading(false);
      toggleEditMode(true);

      return;
    }
    setUser(response);

    setSuccessMessage(response.message);

    toggleLoading(false);
  };

  const changeContent = (e) => {
    setErrorMessage(null);
    setContentVal(e.target.value);
    if (passwordEdit) {
      setPeekPassword(e.target.value);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col
          xs={3}
          className={`${
            editMode ? "align-items-center" : "align-items-start"
          } align-items-md-center d-flex `}
        >
          {title}
        </Col>
        <Col
          xs={9}
          className={`${
            (obj === "email" && email === false) ||
            (email === true && content === null)
              ? "alert profile-alert mb-0"
              : ""
          } justify-content-start justify-content-md-between flex-column flex-md-row d-flex align-items-top align-items-md-center`}
        >
          {editMode ? (
            <Form
              className="d-flex flex-row w-100 align-items-center"
              onSubmit={saveEdit}
            >
              <div className="row mx-0 px-0 w-100">
                <Col className="pl-0">
                  <div className="d-flex flex-column">
                    {passwordEdit ? <small>Old Password</small> : ""}
                    <FormControl
                      type={passwordEdit ? "password" : "text"}
                      autoFocus
                      className="flex-grow mr-2"
                      onChange={
                        passwordEdit ? handleOldPassword : changeContent
                      }
                      value={passwordEdit ? oldPassword : contentVal}
                    />
                  </div>
                </Col>
                {passwordEdit ? (
                  <Col className="pl-0">
                    <div className="d-flex flex-column">
                      <small>New Password</small>
                      <FormControl
                        type={passwordEdit ? "password" : "text"}
                        className="flex-grow mr-2"
                        value={contentVal}
                        onChange={changeContent}
                      />
                      <small>
                        <span
                          className="text-primary pointer-cursor"
                          onClick={handlePasswordToggle}
                        >
                          Peek Password
                        </span>
                        <Badge
                          variant="primary"
                          className={`${
                            !peekPasswordCont ? "d-none" : ""
                          } ml-2`}
                        >
                          {peekPassword}
                        </Badge>
                      </small>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </div>
              <CustomButton additionalClass="mr-2" submit>
                Save
              </CustomButton>
              <CloseIcon
                className="pointer-cursor text-secondary"
                onClick={() => toggleEditMode(false)}
              />
            </Form>
          ) : isLoading ? (
            <React.Fragment>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              <span className="sr-only">Loading...</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {content ? (content === "password" ? "••••••••" : content) : "-"}
              <div className="d-flex flex-row">
                {obj === "email" ? (
                  <React.Fragment>
                    {email === false ? (
                      ""
                    ) : (
                      <Badge
                        className="mr-2"
                        variant={`${
                          email ? "primary" : "secondary"
                        } d-flex align-items-center`}
                      >
                        {email ? "Verified" : "Unverified"}
                      </Badge>
                    )}
                    {email ? (
                      ""
                    ) : (
                      <Badge
                        onClick={handleVerify}
                        className="pointer-cursor mr-2"
                        variant={`${
                          email ? "primary" : "secondary"
                        } d-flex align-items-center`}
                      >
                        Verify Now
                      </Badge>
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
                <small
                  className="pointer-cursor"
                  onClick={() => {
                    toggleEditMode(!editMode);
                    setSuccessMessage(null);
                  }}
                >
                  Edit
                </small>
              </div>
            </React.Fragment>
          )}
        </Col>
      </Row>
      {errorMessage && (
        <Row className="mx-0 justify-content-center">
          <Badge variant="danger mt-2">{errorMessage}</Badge>
        </Row>
      )}
      {successMessage && (
        <Row className="mx-0 justify-content-center">
          <Badge variant="primary mt-2">{successMessage}</Badge>
        </Row>
      )}
      <Row>
        <Alert show={showEmailAlert} variant="warning">
          <Alert.Heading>
            Verification has been sent to your email
          </Alert.Heading>
          <p>
            Please check your email and click the given link. After verifying on
            your email you can refresh the page or click the button below.
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <CustomButton
              isLoading={isLoading}
              onClick={async () => {
                toggleLoading(true);
                const token = await refreshToken(user.uid, setToken);
                await refreshUserData(token, setUser);
                if (user.email_verified === true) {
                  setShowEmailAlert(false);
                }
                toggleLoading(false);
              }}
            >
              Refresh
            </CustomButton>
          </div>
        </Alert>
      </Row>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (token) => dispatch(setUserData(token)),
  setToken: (token) => dispatch(setToken(token)),
  setPassword: (password) => dispatch(setPassword(password)),
});

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserData,
  password: selectCurrentUserPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);

import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


import { analytics } from "../firebase/firebase.utils";

import OrderCompleted from "../components/order-completed.component";
import OrderProcesed from "../components/order-procesed.component";
import { useEffect } from "react";

const Order = () => {
  const [orderMenu] = useState([
    {
      menu: "Processing",
      url: "/order/procesed",
    },
    {
      menu: "Completed",
      url: "/order/completed",
    },
  ]);


  return (
    <section className="support-page narrow-container">
      <Container>
        <Row className="flex-column">
          <Col>
            <h1 className="header-title">Order List</h1>
            <div className="panel-card flex-column">
              <div className="panel-card-header">
                <ul className="card-header-nav m-0 d-flex justify-content-around">
                  {orderMenu.map(({ menu, url }) => (
                    <li
                      key={menu}
                      className={`${
                        url === window.location.pathname ? "active" : ""
                      }`}
                    >
                      <Link to={url}>{menu}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="panel-card-body">
                <Route path="/order/completed" component={OrderCompleted} />
                <Route path="/order/procesed" component={OrderProcesed} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Order;

import React from "react";
// import { Row, Container, Col, Image } from "react-bootstrap";

import AboutBanner from "../components/about-banner.component";
import AboutStory from "../components/about-story.component";

const About = () => (
  <div>
    <AboutBanner />
    <AboutStory />
  </div>
);

export default About;

const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_USER_DATA: "SET_USER_DATA",
  USER_LOG_OUT: "USER_LOG_OUT",
  SET_TOKEN: "SET_TOKEN",
  SET_PASSWORD: "SET_PASSWORD",
  TOGGLE_REGISTER: "TOGGLE_REGISTER",
  USER_VALIDATE: "USER_VALIDATE",
  SET_DELIVERY_ADDRESS: "SET_DELIVERY_ADDRESS",
  SET_BILLING_ADDRESS: "SET_BILLING_ADDRESS",
  USER_CONFIRM_VALIDATE: "USER_CONFIRM_VALIDATE",
};

export default UserActionTypes;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Alert, Col } from "react-bootstrap";

import { setToken } from "../redux/user/user.action";
import { selectCurrentUser } from "../redux/user/user.selector";

import { refreshToken, orderTableFetcher } from "../functions/user.functions";

import ContactBanner from "./contact-banner.component";
import OrderCard from "./order-card.component";

const OrderTable = ({ user, setToken, type }) => {
  const [modal, setModal] = React.useState(false);
  const [orderData, setOrderData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const token = await refreshToken(user.uid, setToken);
      const fetchedData = await orderTableFetcher(token, type);
      setOrderData(fetchedData);
    };

    fetchData();
  }, [refresh]);

  const toggle = () => setModal(!modal);

  const handleAlert = (data) => {
    setAlert(data);
  };

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <React.Fragment>
      {alert && (
        <Col xs={12} md={12}>
          <Alert variant="primary">{alert}</Alert>
        </Col>
      )}
      {orderData ? (
        orderData.length === 0 ? (
          <span className="text-center">No Order Data Yet</span>
        ) : (
          orderData.map((order, index) => (
            <OrderCard
              key={index}
              setToken={setToken}
              uid={user.uid}
              order={order}
              toggleSupportModal={toggle}
              handleAlert={handleAlert}
              toggleRefresh={toggleRefresh}
            />
          ))
        )
      ) : (
        <SkeletonLoader height="100px" borderRadius="20px" />
      )}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Contact for support</ModalHeader>
        <ModalBody>
          <ContactBanner modalSupport />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center"></ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable);

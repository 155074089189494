import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { setAnimEnd } from "../redux/cart-anim/cart-anim.action";
import { selectCartAnimStatus } from "../redux/cart-anim/cart-anim.selector";
import { selectCartItems } from "../redux/cart/cart.selector";

import { ShoppingCartRounded } from "@material-ui/icons";

const CartIcon = ({
  cart,
  marginX,
  animate,
  animateEnd,
  onClick,
  className,
}) => {
  const [quantity, setQuantity] = useState(null);
  useEffect(() => {
    const sumQuantity = cart.reduce((sum, curVal) => {
      return sum + curVal.quantity;
    }, 0);

    setQuantity(sumQuantity);
  }, [cart]);
  return (
    <div
      onClick={onClick}
      onAnimationEnd={animateEnd}
      className={`cart-icon ${marginX ? "mx-3" : ""} ${className && className}`}
    >
      {cart.length > 0 && (
        <div
          className={`text-center ${quantity === 6 && "max-cart"} ${
            animate ? "animate" : ""
          } notify`}
        >
          {quantity === 6 ? "MAX" : quantity}
        </div>
      )}
      <ShoppingCartRounded />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  animate: selectCartAnimStatus,
  cart: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  animateEnd: () => dispatch(setAnimEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);

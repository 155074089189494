import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  selectCurrentUser,
  selectUserValidation,
} from "../redux/user/user.selector";
import { selectPrice, selectCartItems } from "../redux/cart/cart.selector";
import { setPrice } from "../redux/cart/cart.action";

import CustomButton from "./custom-button.component";
import CartList from "./cart-list.component";

const Cart = ({
  price,
  handleMouseDown,
  cartVisibility,
  cart,
  user,
  history,
  setPrice,
  validation,
}) => {
  useEffect(() => {
    setPrice();
  }, [cart]);

  var visibility = "hide";

  if (cartVisibility) {
    visibility = "show";
  }

  const handleCheckOut = () => {
    history.push("/checkout");

    handleMouseDown();
  };

  return (
    <React.Fragment>
      <div className={`cart-bg ${visibility}`} onClick={handleMouseDown}></div>
      <div className={`cart ${visibility}`}>
        <Row className="cart-wrap d-flex flex-column px-5 py-3 pb-4 align-content-between">
          <div className="d-flex flex-column cart-wrap-child cart-top flex-grow-1">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h1 className="header-title mb-3">Cart</h1>
              <CloseRoundedIcon
                onClick={handleMouseDown}
                className="close-cart-action"
              />
            </div>
            <div className="cart-item-wrapper flex-grow-1">
              <CartList />
            </div>
          </div>
          <div className="cart-wrap-child cart-bottom d-flex flex-column justify-content-center">
            <div className="total-price d-flex flex-row align-items-center justify-content-center">
              {price.canPay ? (
                <React.Fragment>
                  Total:
                  <span className="price ml-2">${price.total / 100}</span>
                </React.Fragment>
              ) : (
                user && (
                  <span className="text-center mb-3">
                    Must have exact 3 or 6 items on cart to purchase
                  </span>
                )
              )}
            </div>
            {user ? (
              validation ? (
                price.canPay && (
                  <CustomButton onClick={handleCheckOut} cart large>
                    Checkout
                  </CustomButton>
                )
              ) : (
                <React.Fragment>
                  <span className="smaller text-secondary text-center">
                    Cannot Check Out
                  </span>
                  <span className="text-center mb-3">
                    Please fullfill the requirement before checking out.
                  </span>
                </React.Fragment>
              )
            ) : (
              <CustomButton
                onClick={() => {
                  history.push("/login");
                  handleMouseDown();
                }}
                cart
                large
              >
                Sign up to check out
              </CustomButton>
            )}
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  cart: selectCartItems,
  price: selectPrice,
  validation: selectUserValidation,
});

const mapDispatchToProps = (dispatch) => ({
  setPrice: () => dispatch(setPrice()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));

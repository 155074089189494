import UserActionTypes from "./user.types";

export const setAuth = (item) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: { item },
});

export const setUserData = (item) => ({
  type: UserActionTypes.SET_USER_DATA,
  payload: { item },
});

export const setToken = (item) => ({
  type: UserActionTypes.SET_TOKEN,
  payload: { item },
});

export const setPassword = (item) => ({
  type: UserActionTypes.SET_PASSWORD,
  payload: { item },
});

export const userLogOut = (item) => ({
  type: UserActionTypes.USER_LOG_OUT,
});

export const toggleRegisterStatus = (status) => ({
  type: UserActionTypes.TOGGLE_REGISTER,
  payload: status,
});

export const setValidate = (item) => ({
  type: UserActionTypes.USER_VALIDATE,
  payload: item,
});

export const setConfirmValidate = (item) => ({
  type: UserActionTypes.USER_CONFIRM_VALIDATE,
  payload: item,
});

export const setAddress = (item) => ({
  type: UserActionTypes.SET_DELIVERY_ADDRESS,
  payload: item,
});

export const setBillingAddress = (item) => ({
  type: UserActionTypes.SET_BILLING_ADDRESS,
  payload: item,
});

export const fetchSupportData = async (type) => {
  if (type === "FAQ") {
    var urlEndPoint = "faq";
  } else if (type === "Privacy & Policy") {
    var urlEndPoint = "privacypolicy";
  } else if (type === "How To Buy") {
    var urlEndPoint = "howtobuy";
  }

  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const fetchedData = await fetch(
    process.env.REACT_APP_API_URL + "" + urlEndPoint,
    requestOptions
  );

  const response = await fetchedData.json();
  return response;
};

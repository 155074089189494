import React from "react";
import { Row } from "react-bootstrap";
import OrderTable from "../components/order-table.component";

const OrderProcesed = (props) => {
  return (
    <Row className="billing-address-panel">
      <OrderTable type="Processed" />
    </Row>
  );
};
export default OrderProcesed;

import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  token: null,
  userData: null,
  password: null,
  registering: false,
  validated: false,
  confirmedValidated: true,
  selectedAddress: null,
  selectedBillingAddress: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.item,
      };
    case UserActionTypes.USER_LOG_OUT:
      return {
        ...state,
        currentUser: null,
        token: null,
        userData: null,
        password: null,
      };
    case UserActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload.item.data.token,
      };
    case UserActionTypes.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.item.data,
      };
    case UserActionTypes.SET_PASSWORD:
      return {
        ...state,
        password: action.payload.item,
      };
    case UserActionTypes.TOGGLE_REGISTER:
      return {
        ...state,
        registering: action.payload,
      };
    case UserActionTypes.USER_VALIDATE:
      return {
        ...state,
        validated: action.payload,
      };
    case UserActionTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        selectedBillingAddress: action.payload,
      };
    case UserActionTypes.SET_DELIVERY_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case UserActionTypes.USER_CONFIRM_VALIDATE:
      return {
        ...state,
        confirmedValidated: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;

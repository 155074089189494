import React from "react";
import { Row, Container, Col, Image } from "react-bootstrap";


const AboutBanner = () => (
  <div className="about-banner">
    <Container fluid>
      <Row className="d-flex justify-content-end">
        <Col
          xs={12}
          lg={6}
          className="banner-text text-center d-flex justify-content-end px-0 flex-column"
        >
          <Image
            className="align-self-center align-self-md-end h-100"
            src={require("../assets/about_header_logo.png")}
          ></Image>
        </Col>
      </Row>
    </Container>
  </div>
);

export default AboutBanner;

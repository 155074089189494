import React, { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { Button, Image, Spinner, Row } from "react-bootstrap";
import QRCode from "qrcode.react";

const WechatpayButton = ({ totalPrice, passPayment }) => {
  const [isLoading, setLoading] = useState(false);
  const [payUrl, setPayUrl] = useState(null);
  const [indicator, setIndicator] = useState("");
  const [paymentProcess, setPaymentProcess] = useState(false);
  const stripe = useStripe();
  useEffect(() => {
    setPaymentProcess(true);
    return () => {
      setPaymentProcess(false);
    };
  }, []);
  const handlePayment = async () => {
    setLoading(true);
    await stripe
      .createSource({
        type: "wechat",
        amount: totalPrice * 100,
        currency: "sgd",
      })
      .then(function (result) {
        // indicator.innerHTML=`scan me, if qr code not showing click <a href="${result.source.wechat.qr_code_url}">here</a>`
        setPayUrl(result.source.wechat.qr_code_url);
        pollForSourceStatus(result.source.id, result.source.client_secret);
      });
  };

  let pollCount = 0;
  const MAX_POLL_COUNT = 900; // untuk nunggu pembayaran selama 15 menit

  // bakal run ini untuk verify pembayaran
  const pollForSourceStatus = async (SOURCE_ID, CLIENT_SECRET) => {
    const result = await stripe.retrieveSource({
      id: SOURCE_ID,
      client_secret: CLIENT_SECRET,
    });

    let source = result.source;
    if (source.status === "chargeable") {
      await passPayment(SOURCE_ID, "wechat");
      setIndicator("Paid");

      // Make a request to your server to charge the Source.
      // Depending on the Charge status, show your customer the relevant message.
    } else if (source.status === "pending" && pollCount < MAX_POLL_COUNT) {
      if (paymentProcess === true) {
        // console.log(paymentProcess);
        setIndicator("Please scan QR Code to proceed Payment.");
        pollCount += 1;
        return setTimeout(pollForSourceStatus(SOURCE_ID, CLIENT_SECRET), 1000);
      }
    } else {
      // Depending on the Source status, show your customer the relevant message.
      console.log("failed");
      setIndicator(
        "Payment rejected, please try again or try another payment method."
      );
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {payUrl && (
        <Row className="align-items-center mb-2 mx-0 flex-column">
          <QRCode value={payUrl} />
          <span className="mt-1 mb-2 text-center">
            {indicator ? indicator : ""}
          </span>
          <a
            className={`${isLoading ? "" : "d-none"} w-100`}
            target="_blank"
            rel="noopener noreferrer"
            href={payUrl}
          >
            <Button block variant="primary d-block d-lg-none" className="mb-2">
              Or tap here to pay on WeChat app.
            </Button>
          </a>
        </Row>
      )}
      <Button
        size={"lg"}
        disabled={isLoading}
        block
        variant="google"
        className={`d-flex justify-content-center align-items-center ${
          isLoading ? "flex-column" : ""
        }`}
        onClick={isLoading ? undefined : handlePayment}
      >
        <div className="d-flex flex-row align-items-center">
          {isLoading ? (
            <React.Fragment>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              <span className="sr-only">Loading...</span>
            </React.Fragment>
          ) : (
            ""
          )}
          <Image
            className="mr-3"
            src={require("../assets/wechatpay.png")}
            height="20"
          ></Image>
        </div>
        Pay ${totalPrice} with WeChat Pay
      </Button>
    </React.Fragment>
  );
};

export default WechatpayButton;

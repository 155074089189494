import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
const CheckoutForm = ({
  errorMsg,
  totalPrice,
  passPayment,
  isLoading,
  loadingTrigger,
  setErrorMsg,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setErrorMsg(null);
    // Block native form submission.
    event.preventDefault();
    loadingTrigger(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      loadingTrigger(false);
      console.log("[error]", error);
      setErrorMsg(error.message);
    } else {
      passPayment(paymentMethod.id);
    }
  };

  const dummySubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={isLoading ? dummySubmit : handleSubmit}>
      <CardElement
        options={{ hidePostalCode: true }}
        className="form-control"
      />
      <div className="d-flex justify-content-center flex-column">
        {errorMsg && (
          <span className="text-center py-2 text-secondary">{errorMsg}</span>
        )}

        <button
          className={`mt-3 px-5 btn btn-primary btn-lg ${
            isLoading && "disabled"
          }`}
          type="submit"
          disabled={!stripe}
        >
          {isLoading ? (
            <React.Fragment>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              <span className="sr-only">Loading...</span>
            </React.Fragment>
          ) : (
            ""
          )}
          Pay ${totalPrice}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;

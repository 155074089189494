import React, { useState } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
  selectCurrentUserData,
  selectCurrentUserPassword,
} from "../redux/user/user.selector";
import { setUserData, setToken } from "../redux/user/user.action";

import { refreshToken, updateUserData } from "../functions/user.functions";

import CustomButton from "../components/custom-button.component";
import ProfileEditor from "../components/profile-editor.component";

const AccountSettings = ({ user, setUser, setToken, password }) => {
  const [uploadImage, toggleUploadImage] = useState(false);
  const [imageRaw, setImageRaw] = useState(null);
  const [imageName, setImageName] = useState("Select Image File");
  const [isUploadLoading, toggleUploadLoading] = useState(false);

  const handleImageInput = (e) => {
    setImageRaw(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleSubmitImage = async () => {
    toggleUploadLoading(true);

    const token = await refreshToken(user.uid, setToken);

    const response = await updateUserData("avatar", token, imageRaw, password);

    setUser(response);

    toggleUploadImage(false);
    toggleUploadLoading(false);
  };

  return (
    <Row className="account-settings justify-content">
      <Col
        xs={12}
        md={5}
        className="change-profile-picture align-content-around justify-content-center d-flex flex-wrap align-self-start"
      >
        <Image className="mb-3 align-self-center" src={user.avatar}></Image>
        {uploadImage ? (
          <React.Fragment>
            <span className="mb-3">
              Maximum file size: 5MB. <br />
              Allowed extension: .PNG .JPG .JPEG
            </span>
            <Form className="mb-3">
              <Form.File
                onChange={handleImageInput}
                id="custom-file"
                label={imageName}
                custom
              />
            </Form>
            {isUploadLoading ? (
              ""
            ) : (
              <CustomButton
                cart
                additionalClass="mx-2"
                onClick={() => toggleUploadImage(!uploadImage)}
              >
                Cancel
              </CustomButton>
            )}
            <CustomButton
              isLoading={isUploadLoading}
              additionalClass="mx-2"
              onClick={handleSubmitImage}
            >
              Upload
            </CustomButton>
          </React.Fragment>
        ) : (
          <CustomButton
            onClick={() => toggleUploadImage(!uploadImage)}
            cart
            large
          >
            Change Profile Picture
          </CustomButton>
        )}
      </Col>
      <Col
        xs={12}
        md={7}
        className="bio pt-3 pl-0 pl-md-5 d-flex flex-column mt-3 mt-md-0"
      >
        <strong>Biodata</strong>
        <ProfileEditor title="Name" obj="name" content={user.name} />
        <strong>Contact</strong>
        <ProfileEditor
          title="Email & Username"
          email={user.email_verified}
          obj="email"
          content={user.email}
        />
        <ProfileEditor
          title="Phone"
          email={user.email_verified}
          obj="phone"
          phoneEdit
          content={user.phone_number}
        />
        {user.registered_by === "ep" && (
          <React.Fragment>
            <strong>Password</strong>
            <ProfileEditor
              title="Password"
              obj="password"
              passwordEdit
              content="password"
            />
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserData,
  password: selectCurrentUserPassword,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (token) => dispatch(setUserData(token)),
  setToken: (token) => dispatch(setToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

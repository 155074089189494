import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { setToken } from "../redux/user/user.action";
import { selectCurrentUserData } from "../redux/user/user.selector";

import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

const CheckoutButton = ({ totalPrice, passPayment, redirect }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: "SG",
          currency: "sgd",
          total: {
            label: "DFF Checkout",
            amount: totalPrice * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }
        });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [stripe]);

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev) => {
      // console.log("ev id", ev.id);
      // console.log("ev pm_id", ev.paymentMethod.id);
      const x = await passPayment(ev.paymentMethod.id, "Instant");
      const clientSecret = await x.data.client_secret;
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );
      ev.complete("success");
      const { error } = await stripe.confirmCardPayment(clientSecret);
      if (error) {
        console.log("error", error);
        // The payment failed -- ask your customer for a new payment method.
      } else {
        redirect(x.status_code);
      }
    });

    return (
      <div className="payment-button d-flex flex-column mb-2">
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </div>
    );
  }

  // Use a traditional checkout form.
  return (
    <span className="text-center">
      <center>
        The current device / browser defer you from paying with this method.
        Please choose other payment options.
      </center>
    </span>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton);

import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { selectPrice, selectCartItems } from "../redux/cart/cart.selector";
import { selectStoreStatus } from "../redux/fruits/fruits.selector";

import CustomButtom from "../components/custom-button.component";

const CheckoutSummary = ({
  price,
  cart,
  canProceed,
  modalToggle,
  totalPriceReceiver,
  deliveryAddressLoading,
  postalStatus,
  storeStatus,
}) => {
  useEffect(() => {
    totalPriceReceiver(price.total / 100);
  }, [price]);

  return (
    <div className="card summarry-address-card">
      <strong className="card-title">Summary</strong>
      {deliveryAddressLoading ? (
        <React.Fragment>
          <SkeletonLoader height="20px" borderRadius="20px" />
          <div className="mt-3" />
          <SkeletonLoader height="30px" borderRadius="20px" />
        </React.Fragment>
      ) : postalStatus ? (
        <React.Fragment>
          {cart.length === 0 ? (
            <span className="text-center">
              Please add some product before proceeding
            </span>
          ) : price.canPay ? (
            canProceed ? (
              <React.Fragment>
                <div className="card-content">
                  <Row>
                    <Col xs={8}>
                      <span className="font-weight-bold">Fruits</span>
                      <ul>
                        {cart.map((item, index) => (
                          <li key={index}>
                            {item.name +
                              (item.quantity > 1 ? " ⨉ " + item.quantity : "")}
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col xs={4} className="price">
                      ${price.total / 100}
                    </Col>
                  </Row>
                  <Row className="checkout-total">
                    <Col xs={12} className="border-top mt-2 mb-2"></Col>
                    <Col xs={8}>TOTAL</Col>
                    <Col xs={4} className="price">
                      ${price.total / 100}
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="text-center">
                  Either your delivery address or billing address is empty. Add
                  one to proceed.
                </span>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <span className="text-center">
                Must have either 3 or 6 items in the cart.
              </span>
            </React.Fragment>
          )}
          <CustomButtom
            onClick={!price.canPay || !canProceed || !storeStatus ? undefined : modalToggle}
            additionalClass={`${
              (!price.canPay || !canProceed || !storeStatus) && "disabled btn-disable"
            } mt-3 btn-lg`}
          >
            {storeStatus === true
              ? !price.canPay || !canProceed
                ? "Cannot Pay"
                : `Pay \$${price.total / 100}`
              : "Store is closed for today"}
          </CustomButtom>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className="text-center">
            Sorry, our delivery address is beyond our coverage.
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  price: selectPrice,
  cart: selectCartItems,
  storeStatus: selectStoreStatus,
});

export default connect(mapStateToProps)(CheckoutSummary);

const INITIAL_STATE = {
  mainMenu: [
    {
      menu: "Home",
      url: "/",
    },
    {
      menu: "About",
      url: "/about",
    }, 
  ],
};

const mainNavReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default mainNavReducer;

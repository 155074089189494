import CartActionTypes from "./cart.types";

export const addToCart = (item) => ({
  type: CartActionTypes.CART_ADD,
  payload: { item },
});

export const removeFromCart = (item) => ({
  type: CartActionTypes.CART_DELETE,
  payload: { item },
});

export const setPrice = () => ({
  type: CartActionTypes.SET_PRICE,
});

export const wipeCart = () => ({
  type: CartActionTypes.WIPE_CART,
});

export const addOneToCart = (id) => ({
  type: CartActionTypes.CART_INCREMENT,
  payload: id,
});

export const removeOneFromCart = (id) => ({
  type: CartActionTypes.CART_DECREMENT,
  payload: id,
});

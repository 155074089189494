import React from "react";
import { Image } from "react-bootstrap";

const ProfilePicture = ({ width, marginX, src }) => (
  <Image
    className={`${marginX ? "mx-3" : ""}`}
    src={src}
    width={width}
    roundedCircle
    fluid
  />
);

export default ProfilePicture;

import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { refreshToken } from "../functions/user.functions";
import { groupBy } from "../functions/utils.functions";

import CustomButton from "./custom-button.component";

const OrderCard = ({
  order,
  toggleSupportModal,
  uid,
  setToken,
  handleAlert,
  toggleRefresh,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [modalComplete, setModalComplete] = React.useState(false);
  const [detail_orders, setDetailOrders] = useState([]);

  useEffect(() => {
    const groupedCart = groupBy(order.detail_orders, "product_id");
    setDetailOrders(Object.values(groupedCart));
  }, [order]);

  const toggleComplete = () => setModalComplete(!modalComplete);

  const handleOrderComplete = async () => {
    setLoading(true);

    const token = await refreshToken(uid, setToken);
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("order_id", order.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const fetchData = await fetch(
      process.env.REACT_APP_API_URL + "user/order/changetocompleted",
      requestOptions
    );

    const response = await fetchData.json();
    toggleRefresh();
    handleAlert(response.message);
    setModalComplete(!modalComplete);
    setLoading(false);
  };
  return (
    <Col xs={12} md={6} className="px-2 pb-2 d-flex flex-column">
      <div className="d-flex flex-column order-card p-3 flex-grow-1">
        <Modal isOpen={modalComplete} toggle={toggleComplete}>
          <ModalHeader toggle={toggleComplete}>
            Confirm Order Completion?
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <span className="text-center font-weight-bold text-secondary">
              Make sure your order has arrived before completing order.
            </span>
            <span className="text-center mb-3">
              Are you sure to complete order{" "}
              <span className="font-weight-bold">#{order.invoice_id}</span>?
            </span>
            <CustomButton
              onClick={handleOrderComplete}
              isLoading={isLoading}
              additionalClass="btn-block"
            >
              Complete Order
            </CustomButton>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center"></ModalFooter>
        </Modal>
        <strong>#{order.invoice_id}</strong>
        <span>
          <span className="font-weight-bold text-primary">
            ${order.price / 100}
          </span>{" "}
          -{" "}
          {detail_orders.map((item, index) => {
            if (detail_orders.length === index + 1) {
              return item[0].product_name + " x " + item.length;
            } else {
              return item[0].product_name + " x " + item.length + ", ";
            }
          })}
        </span>
        <div className="mt-3" />
        <span className="smaller">Ordered on</span>
        <span>{order.date_time}</span>
        <div className="mt-3" />
        <span className="small">Delivered to </span>
        <span className="font-weight-bold larger">{order.recipient}</span>

        <span>
          <span className="font-weight-bold">[{order.name_address}]</span>{" "}
          {order.address}
        </span>

        <span>
          Status : &nbsp;
          <span className={`${order.status === "Packing" && "text-secondary"}`}>
            {order.status}
          </span>
        </span>

        <div className="d-flex justify-content-end mt-2">
          <CustomButton
            additionalClass="mr-2"
            cart
            onClick={toggleSupportModal}
          >
            Get Support
          </CustomButton>
          {order.status !== "Completed" && (
            <CustomButton onClick={toggleComplete}>Complete</CustomButton>
          )}
        </div>
      </div>
    </Col>
  );
};

export default OrderCard;

import React, { useState, useEffect } from "react";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { fetchSupportData } from "../functions/support.functions";

const SupportText = ({ title }) => {
  const [text, setText] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchSupportData(title);
      setText(response.data.body);
    };
    fetchData();
  }, [title]);
  return (
    <div className="support-text">
      <h1 className="header-title">{title}</h1>
      <div className="support-text-content">
        {text ? (
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        ) : (
          <React.Fragment>
            <SkeletonLoader height="40px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-4"></div>
            <SkeletonLoader height="40px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-2"></div>
            <SkeletonLoader height="20px"></SkeletonLoader>
            <div className="mb-4"></div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SupportText;

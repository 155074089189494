import React from "react";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { selectFruitsOfTheDay } from "../redux/fruits/fruits.selector";

import FruitCard from "./fruit-card.component";

const FruitOfTheDay = ({ fruitsOfTheDay }) => {
  let shimmer = [];
  for (let i = 0; i <= 1; ++i) {
    shimmer.push(
      <Col xs={6} md={6} key={i} className="mb-4">
        <SkeletonLoader height="250px" borderRadius="20px" />
      </Col>
    );
  }

  return (
    <Col lg={6}>
      <h1 className="header-title">Fruits of the day</h1>
      <Row>
        {fruitsOfTheDay ? (
          fruitsOfTheDay.map((props) => (
            <Col key={props.id} xs={6} md={6}>
              <FruitCard {...props} cardType="fruitsOfTheDay" />
            </Col>
          ))
        ) : (
          <React.Fragment>{shimmer}</React.Fragment>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = createStructuredSelector({
  fruitsOfTheDay: selectFruitsOfTheDay,
});

export default connect(mapStateToProps)(FruitOfTheDay);

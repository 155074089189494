import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectCurrentUserData } from "../redux/user/user.selector";

const ProtectedRoute = ({ currentUser, component: Component, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        currentUser ? <Component {...props} /> : <Redirect to="/login" />
      }
    ></Route>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUserData,
});

export default connect(mapStateToProps)(ProtectedRoute);

import CartAnimActionTypes from "./cart-anim.types";

const INITIAL_STATE = {
  cartAnimStatus: false,
};

const cartAnimReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartAnimActionTypes.CART_ANIM_START:
      return {
        ...state,
        cartAnimStatus: true,
      };
    case CartAnimActionTypes.CART_ANIM_END:
      return {
        ...state,
        cartAnimStatus: false,
      };
    default:
      return state;
  }
};

export default cartAnimReducer;

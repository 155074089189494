import React, { useState } from "react";
import { Container, Row, Image } from "react-bootstrap";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Link } from "react-router-dom";

import MobileMenu from "./mobile-menu.component";

const MobileHeader = () => {
  const [menu, toggleMenu] = useState(false);
  const menuToggler = (toggle) => {
    if (toggle === null) {
      toggleMenu(!menu);
      return;
    }
    toggleMenu(toggle);
  };
  return (
    <React.Fragment>
      <div className="mobile-header d-block d-md-none">
        <Container fluid>
          <Row className="px-3 py-3 justify-content-between">
            <Link to="/">
              <Image src={require("../../assets/logo.png")} height="30"></Image>
            </Link>
            {menu ? (
              <CloseRoundedIcon onClick={() => toggleMenu(!menu)} />
            ) : (
              <MenuRoundedIcon onClick={() => toggleMenu(!menu)} />
            )}
          </Row>
        </Container>
      </div>
      <MobileMenu menu={menu} toggler={menuToggler} />
    </React.Fragment>
  );
};

export default MobileHeader;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import PersonIcon from "@material-ui/icons/Person";
import ReceiptRoundedIcon from "@material-ui/icons/ReceiptRounded";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCurrentUser } from "../../redux/user/user.selector";

import CartIcon from "../cart-icon.component";

const MobileNavbar = ({ user }) => {
  return (
    <div className="mobile-navbar d-block d-md-none">
      <Container fluid>
        <Row className="px-2 py-2 justify-content-center align-items-center">
          <Col xs={3}>
            <Link
              to="/"
              className="d-flex flex-column align-items-center justify-content-center text-primary"
            >
              <HomeRoundedIcon className="justify-self-center mobile-nav-icon" />
              <span className="mobile-nav-title">Home</span>
            </Link>
          </Col>
          <Col xs={3}>
            <Link
              to="/cart"
              className="d-flex flex-column align-items-center justify-content-center text-primary"
            >
              <CartIcon className="justify-self-center mobile-nav-icon" />

              <span className="mobile-nav-title">Cart</span>
            </Link>
          </Col>
          {user && (
            <Col xs={3}>
              <Link
                to="/order/procesed"
                className="d-flex flex-column align-items-center justify-content-center text-primary"
              >
                <ReceiptRoundedIcon className="justify-self-center mobile-nav-icon" />
                <span className="mobile-nav-title">Orders</span>
              </Link>
            </Col>
          )}
          <Col xs={3}>
            <Link
              to={user ? "/settings/account" : "/login"}
              className="d-flex flex-column align-items-center justify-content-center text-primary"
            >
              <PersonIcon className="justify-self-center mobile-nav-icon" />
              <span className="mobile-nav-title">Account</span>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(MobileNavbar);

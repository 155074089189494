import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { selectCartItems } from "../redux/cart/cart.selector";

import CartItem from "./cart-item.component";

const CartList = ({ cart }) => {
  return (
    <React.Fragment>
      {cart.length === 0 ? (
        <span className="text-center w-100">
          You haven't add anything to your cart yet
        </span>
      ) : (
        cart.map((cartItems, index) => <CartItem key={index} {...cartItems} />)
      )}
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  cart: selectCartItems,
});

export default connect(mapStateToProps)(CartList);

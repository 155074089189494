const INITIAL_STATE = {
  supportLinks: [
    {
      title: "How To Buy",
      url: "/support/how-to-buy",
    },
    {
      title: "FAQ",
      url: "/support/faq",
    },
    {
      title: "Privacy & Policy",
      url: "/support/privacy-policy",
    },
  ],
};

const supportLinkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default supportLinkReducer;

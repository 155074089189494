import React, { useEffect, useState } from "react";
import { Image, Tooltip, OverlayTrigger } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { analytics } from "../firebase/firebase.utils";

import {
  removeFromCart,
  addOneToCart,
  removeOneFromCart,
} from "../redux/cart/cart.action";
import { selectCartItems } from "../redux/cart/cart.selector";
import { selectCurrentUserData } from "../redux/user/user.selector";

import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";

function renderTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      Remove from cart
    </Tooltip>
  );
}

function renderTooltipAdd(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      +1
    </Tooltip>
  );
}

function renderTooltipRemove(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      -1
    </Tooltip>
  );
}

const CartItem = ({
  removeFromCart,
  images,
  name,
  quantity,
  id,
  add,
  remove,
  cart,
  user,
}) => {
  const [totalQuantity, setTotalQuantity] = useState(null);
  useEffect(() => {
    const sumQuantity = cart.reduce((sum, curVal) => {
      return sum + curVal.quantity;
    }, 0);
    setTotalQuantity(sumQuantity);
  }, [cart]);

  const addItem = (id, name) => {
    add(id);
    analytics.logEvent("fruit_added_to_cart", {
      id: id,
      name: name,
      user: user.email,
    });
  };

  const removeItem = (id, name) => {
    remove(id);
    analytics.logEvent("fruit_removed_to_cart", {
      id: id,
      name: name,
      user: user.email,
    });
  };
  return (
    <div className="cart-item d-flex flex-row justify-content-between align-items-center mb-3">
      <div className="item">
        <Image className="product-image" src={images} height="85"></Image>
        <span className="item-name ml-3">
          {name.length > 16 ? name.substring(0, 16) + "..." : name}{" "}
          <span className="text-primary">
            {quantity > 1 && "⨉ " + quantity}
          </span>
        </span>
      </div>
      <div className="cart-button-group">
        {totalQuantity !== 6 && (
          <OverlayTrigger placement="top" overlay={renderTooltipAdd}>
            <AddRoundedIcon
              onClick={() => addItem(id, name)}
              className="text-primary pointer-cursor"
            />
          </OverlayTrigger>
        )}

        {quantity > 1 && (
          <OverlayTrigger placement="top" overlay={renderTooltipRemove}>
            <RemoveRoundedIcon
              onClick={() => removeItem(id, name)}
              className="text-primary pointer-cursor"
            />
          </OverlayTrigger>
        )}

        <OverlayTrigger placement="left" overlay={renderTooltip}>
          <DeleteForeverRoundedIcon
            onClick={() => removeFromCart(id)}
            className="text-secondary pointer-cursor ml-3"
          />
        </OverlayTrigger>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (item) => dispatch(removeFromCart(item)),
  add: (id) => dispatch(addOneToCart(id)),
  remove: (id) => dispatch(removeOneFromCart(id)),
});

const mapStateToProps = createStructuredSelector({
  cart: selectCartItems,
  user: selectCurrentUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);

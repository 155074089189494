import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SkeletonLoader from "tiny-skeleton-loader-react";

import {
  refreshUserData,
  refreshToken,
  fetchDeliveryAddress,
  fetchBillingAddress,
} from "../functions/user.functions";
import { setToken, setUserData } from "../redux/user/user.action";

import { selectCurrentUser } from "../redux/user/user.selector";

import AddressCard from "./address-card.component";

const AddressTable = ({
  user,
  setToken,
  trigger,
  type,
  mode,
  setCheckOutAddress,
  setUser,
}) => {
  const [Addresses, setAddresses] = useState(null);
  const [selfTrigger, toggleSelfTrigger] = useState(false);
  useEffect(() => {
    setAddresses(null);
    const fetchData = async () => {
      if (type === "Delivery") {
        const token = await refreshToken(user.uid, setToken);
        await refreshUserData(token, setUser);
        const fetchAddress = await fetchDeliveryAddress(token);
        const data = fetchAddress.data;
        setAddresses(data);
      } else if (type === "Billing") {
        const token = await refreshToken(user.uid, setToken);
        await refreshUserData(token, setUser);
        const fetchAddress = await fetchBillingAddress(token);
        const data = fetchAddress.data;
        setAddresses(data);
      }
    };

    fetchData();
  }, [trigger, selfTrigger]);

  return (
    <React.Fragment>
      <Row>
        {Addresses ? (
          Addresses.length === 0 ? (
            <Col>
              <span className="text-center">No Address Data</span>
            </Col>
          ) : (
            Addresses.map((data, index) => (
              <AddressCard
                user={user}
                setToken={setToken}
                type={type}
                mode={mode}
                setCheckOutAddress={setCheckOutAddress}
                selfTrigger={selfTrigger}
                toggleSelfTrigger={toggleSelfTrigger}
                key={index}
                data={data}
              />
            ))
          )
        ) : (
          <React.Fragment>
            <Col>
              <SkeletonLoader height="150px" borderRadius="20px" />
            </Col>
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  setUser: (user) => dispatch(setUserData(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressTable);

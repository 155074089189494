import React from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { analytics } from "../firebase/firebase.utils";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";

import { setAnimStart } from "../redux/cart-anim/cart-anim.action";
import { addToCart } from "../redux/cart/cart.action";
import { selectPrice } from "../redux/cart/cart.selector";

import { selectCurrentUserData } from "../redux/user/user.selector";
import {
  setFruitSlideModalStatus,
  setFruitSlideModalImages,
} from "../redux/fruits/fruit.action";

import CustomButton from "./custom-button.component";

const FruitCard = ({
  startCartAnimate,
  cardType,
  name,
  sold,
  images,
  id,
  addToCart,
  price,
  user,
  setFruitSlideModalStatus,
  setFruitSlideModalImages,
  ...props
}) => {
  const imageFile = images[0];

  const fruit = {
    id: id,
    name: name,
    images: imageFile.image,
  };

  const handleAddToCartButton = () => {
    analytics.logEvent("fruit_added_to_cart", {
      id: id,
      name: name,
      user: user ? user.email : "Unregistered User",
    });
    startCartAnimate();
    addToCart(fruit);
  };

  var cardClass;
  switch (cardType) {
    case "mostPopular":
      cardClass = "most-popular";
      break;
    case "fruitsOfTheDay":
      cardClass = "fruit-of-the-day";
      break;
    case "collection":
      cardClass = "collection-fruit";
      break;
    default:
  }

  const handleImageModal = () => {
    setFruitSlideModalImages(images);
    setFruitSlideModalStatus(true);
  };

  // {images ? images.slice(0, 1).map((tes) => "tes") : ""}
  return (
    <div className={`fruit-card ${cardClass} d-flex flex-grow-1`}>
      <div className="fruit-image-container d-flex justify-content-center">
        <Image
          className="product-image"
          src={
            imageFile ? imageFile.image : require("../assets/default-fruit.png")
          }
        ></Image>
        <div
          className={`d-flex fruit-image-overlay align-items-center justify-content-center zoom`}
          onClick={handleImageModal}
        >
          <ZoomInRoundedIcon className="zoom-icon"></ZoomInRoundedIcon>
        </div>
      </div>
      <div className="d-flex product-details">
        <span className="product-title">{name}</span>
        <span className="product-subtitle">{sold} Purchases</span>
        <CustomButton
          additionalClass={!price.canAdd && "disabled btn-disable"}
          onClick={price.canAdd ? handleAddToCartButton : undefined}
          cart
        >
          Add To Cart
        </CustomButton>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  price: selectPrice,
  user: selectCurrentUserData,
});

const mapDispatchToProps = (dispatch) => ({
  startCartAnimate: () => dispatch(setAnimStart()),
  addToCart: (item) => dispatch(addToCart(item)),
  setFruitSlideModalStatus: (status) =>
    dispatch(setFruitSlideModalStatus(status)),
  setFruitSlideModalImages: (images) =>
    dispatch(setFruitSlideModalImages(images)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FruitCard);

import axios from "axios";

export const refreshUserData = async (token, setUser) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);
  var requestOptionsGet = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const fetchedData = await fetch(
    process.env.REACT_APP_API_URL + "user/self",
    requestOptionsGet
  );
  const response = await fetchedData.json();
  await setUser(response);
  return response;
};

export const refreshToken = async (uid, setToken) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_uid", uid);
  const fetching = await fetch(process.env.REACT_APP_API_URL + "login", {
    method: "POST",
    body: urlencoded,
    redirect: "follow",
  });

  const response = await fetching.json();
  setToken(response);

  return response.data.token;
};
export const updateUserData = async (obj, token, contentVal, password) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);

  var formdata = new FormData();
  formdata.append("_method", "PUT");
  formdata.append(obj, contentVal);
  formdata.append("currentPassword", password);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const response = await fetch(
    process.env.REACT_APP_API_URL + "user/update",
    requestOptions
  );

  const resJson = await response.json();

  return resJson;
};

export const fetchDeliveryAddress = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const fetchData = await fetch(
    process.env.REACT_APP_API_URL + "user/address",
    requestOptions
  );

  const response = await fetchData.json();

  return response;
};

export const addAddress = async (
  uid,
  setToken,
  title,
  address,
  postal,
  recipient,
  phone
) => {
  const token = await refreshToken(uid, setToken);

  var myHeaders = new Headers();
  myHeaders.append("token", token);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("name", title);
  urlencoded.append("address", address);
  urlencoded.append("postal_code", postal);
  urlencoded.append("recipient", recipient);
  urlencoded.append("phone_number", phone);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  await fetch(process.env.REACT_APP_API_URL + "user/address", requestOptions);
};

export const fetchBillingAddress = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const fetchData = await fetch(
    process.env.REACT_APP_API_URL + "user/billing",
    requestOptions
  );

  const response = await fetchData.json();

  return response;
};

export const addBillingAddress = async (
  uid,
  setToken,
  title,
  address,
  postal,
  recipient,
  phone
) => {
  const token = await refreshToken(uid, setToken);

  var myHeaders = new Headers();
  myHeaders.append("token", token);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("name", title);
  urlencoded.append("address", address);
  urlencoded.append("postal_code", postal);
  urlencoded.append("recipient", recipient);
  urlencoded.append("phone_number", phone);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  await fetch(process.env.REACT_APP_API_URL + "user/billing", requestOptions);
};

export const deleteAddress = async (addressId, type, token) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();

  if (type === "Delivery") {
    urlencoded.append("address_id", addressId);
    var endpoint = process.env.REACT_APP_API_URL + "user/address";
  } else if (type === "Billing") {
    urlencoded.append("billing_id", addressId);
    var endpoint = process.env.REACT_APP_API_URL + "user/billing";
  }
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  await fetch(endpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => result.json())
    .catch((error) => console.log("error", error));
};

export const finalizePayment = async (
  notUseBilling = false,
  payment_id,
  cart,
  deliveryAddress,
  billingAddress,
  price,
  deliveryCost = 1000,
  token,
  type = "Card"
) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);

  var urlencoded = new URLSearchParams();
  urlencoded.append("delivery_name_address", deliveryAddress.name);
  urlencoded.append("delivery_address", deliveryAddress.address);
  urlencoded.append("delivery_postal_code", deliveryAddress.postal_code);
  urlencoded.append("delivery_recipient", deliveryAddress.recipient);
  urlencoded.append("delivery_phone_number", deliveryAddress.phone_number);
  if (notUseBilling === true) {
    urlencoded.append("billing_name_address", deliveryAddress.name);
    urlencoded.append("billing_address", deliveryAddress.address);
    urlencoded.append("billing_postal_code", deliveryAddress.postal_code);
    urlencoded.append("billing_recipient", deliveryAddress.recipient);
    urlencoded.append("billing_phone_number", deliveryAddress.phone_number);
  } else {
    urlencoded.append("billing_name_address", billingAddress.name);
    urlencoded.append("billing_address", billingAddress.address);
    urlencoded.append("billing_postal_code", billingAddress.postal_code);
    urlencoded.append("billing_recipient", billingAddress.recipient);
    urlencoded.append("billing_phone_number", billingAddress.phone_number);
  }

  urlencoded.append("price", price);
  urlencoded.append("delivery_cost", 0);
  if (type === "alipay" || type === "wechat") {
    urlencoded.append("source_id", payment_id);
  } else {
    urlencoded.append("paymentMethod_id", payment_id);
  }

  urlencoded.append("type", type);

  cart.map((item) => {
    for (let index = 0; index < item.quantity; index++) {
      urlencoded.append("products_id[]", item.id);
    }
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const fetching = await fetch(
    process.env.REACT_APP_API_URL + "user/order/withpayment",
    requestOptions
  );

  const response = await fetching.json();
  // console.log(response);
  return response;
};

export const orderTableFetcher = async (token, type) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);
  var endPoint;
  if (type === "Processed") {
    endPoint = process.env.REACT_APP_API_URL + "user/order/onprocess";
  } else if (type === "Completed") {
    endPoint = process.env.REACT_APP_API_URL + "user/order/completed";
  }

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const dataFetched = await fetch(endPoint, requestOptions);

  const response = await dataFetched.json();
  return response.data;
};

export const registerUser = async (uid, name, registerBy) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_uid", uid);
  urlencoded.append("name", name);
  urlencoded.append("registered_by", registerBy);

  var requestOptions = {
    method: "POST",
    redirect: "follow",
  };

  const fetchData = await axios.post(
    process.env.REACT_APP_API_URL + "register",
    urlencoded,
    requestOptions
  );
  return fetchData;
};

export const newUserAuth = async (
  user,
  name,
  type,
  password,
  setPassword,
  setAuth,
  setToken,
  setUser
) => {
  await registerUser(user.uid, name, type);
  if (password !== null) {
    await setPassword(password);
  }
  await setAuth(user);
  const token = await refreshToken(user.uid, setToken);
  const response = await refreshUserData(token, setUser);
  return response;
};

export const forgetPassword = async (email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", email);
  urlencoded.append("continue_url", process.env.REACT_APP_BASE_URL + "login");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const fetchedData = await fetch(
    process.env.REACT_APP_API_URL + "forget-password",
    requestOptions
  );

  const response = await fetchedData.json();

  return response;
};

export const convertFromEp = async (token, userType) => {
  var myHeaders = new Headers();
  myHeaders.append("token", token);

  var urlencoded = new URLSearchParams();
  urlencoded.append("registered_by", userType);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  const fetchedData = await fetch(
    process.env.REACT_APP_API_URL + "user/convertFromEp",
    requestOptions
  );

  const response = await fetchedData.json();
  return response;
};

export const getUserType = async (email) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("email", email);

  var requestOptions = {
    method: "POST",
    body: urlencoded,
    redirect: "follow",
  };

  const fetchedData = await fetch(
    process.env.REACT_APP_API_URL + "user/getregisteredby",
    requestOptions
  );

  const response = await fetchedData.json();
  return response;
};

import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect } from "react-router-dom";

import { analytics } from "../firebase/firebase.utils";
import { selectCartItems, selectPrice } from "../redux/cart/cart.selector";
import {
  selectCurrentUser,
  selectUserValidation,
  selectUserAddress,
  selectUserBillingAddress,
} from "../redux/user/user.selector";
import {
  setToken,
  setAddress,
  setBillingAddress,
} from "../redux/user/user.action";
import { wipeCart } from "../redux/cart/cart.action";

import { refreshToken, finalizePayment } from "../functions/user.functions";

import CheckoutAddress from "../components/checkout-address.component";
import CheckoutSummary from "../components/checkout-summary.component";
import CartList from "../components/cart-list.component";
import CheckoutForm from "../components/checkout-form.component";
import CheckoutButton from "../components/checkout-button.component";
import AlipayButton from "../components/alipay-button.component";
import WechatpayButton from "../components/wechatpay-button.component";

const CheckOut = ({
  cart,
  price,
  user,
  setToken,
  history,
  wipeCart,
  validation,
  setUserBillingAddress,
  setUserAddress,
  userAddress,
  userBillingAddress,
}) => {
  const [modal, setModal] = React.useState(false);
  const [isLoading, toggleLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [canProceed, toggleProceed] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [notUseBilling, setNotUseBilling] = useState(true);
  const [deliveryAddressLoading, setDeliveryAddressLoading] = useState(true);
  const [billingAddressLoading, setBillingAddressLoading] = useState(true);
  const [postalStatus, setPostalStatus] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    if (deliveryAddress && billingAddress) {
      toggleProceed(true);
    } else {
      toggleProceed(false);
    }
    if (notUseBilling === true) {
      toggleProceed(true);
      setUserBillingAddress(userAddress);
    }
  }, [deliveryAddress, billingAddress, notUseBilling]);

  useEffect(() => {
    if (userAddress) {
      analytics.logEvent("postal_out_of_bound", {
        email: user.email,
        postal_code: userAddress.postal_code,
      });
    }
  }, [postalStatus]);

  const errorMsgSet = (data) => {
    setErrorMsg(data);
  };

  const loadingStart = (status) => {
    toggleLoading(status);
  };

  const recieveDeliveryAddress = (data) => {
    setDeliveryAddress(data);
    setUserAddress(data);
  };
  const receiveBillingAddress = (data) => {
    setBillingAddress(data);
    setUserBillingAddress(data);
  };

  const toggle = () => {
    if (isLoading) {
      return;
    }
    setModal(!modal);
  };

  const dispatchPaymentFinal = async (
    payment_id,
    cart,
    deliveryAddress,
    billingAddress,
    price,
    deliveryCost = 1000,
    type
  ) => {
    const token = await refreshToken(user.uid, setToken);
    const response = await finalizePayment(
      notUseBilling,
      payment_id,
      cart,
      userAddress,
      userBillingAddress,
      price,
      (deliveryCost = 1000),
      token,
      type
    );
    if (type === "Instant") {
      return response;
    } else {
      redirect(response.status_code);
    }
  };

  const redirect = (status_code) => {
    if (status_code === 201) {
      toggleLoading(false);
      toggle();
      wipeCart();
      history.push("/thankyou");
    } else {
      toggleLoading(false);
      setErrorMsg(
        "Unexpected error happened, please try again. If problem persist, try again later or call customer service for support."
      );
    }
  };

  const handlePaymentFinal = async (paymentId, type = "Card") => {
    const response = await dispatchPaymentFinal(
      paymentId,
      cart,
      deliveryAddress,
      billingAddress,
      price.total,
      1000,
      type
    );

    return response;
  };

  const toggleNotUseBilling = (bool) => {
    setNotUseBilling(bool);
  };

  const handlePostalStatus = (data) => {
    setPostalStatus(data);
  };

  if (validation === false) {
    return <Redirect to="/" />;
  } else {
    return (
      <section className="checkout-page">
        <Container>
          <Row className="d-flex flex-column">
            <Col>
              <h1 className="header-title">Check Out</h1>
              <div className="checkout-cards row">
                <Col xs={12} md={6}>
                  <div className="card items-card">
                    <strong className="card-title">Items</strong>
                    <CartList />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <CheckoutAddress
                    className="mt-3 mt-md-0"
                    receiver={recieveDeliveryAddress}
                    type="Delivery"
                    toggleProceed={toggleProceed}
                    notUseBilling={notUseBilling}
                    toggleNotUseBilling={toggleNotUseBilling}
                    setLoading={(data) => setDeliveryAddressLoading(data)}
                    setPostalStatus={handlePostalStatus}
                  />
                  {!notUseBilling && (
                    <CheckoutAddress
                      receiver={receiveBillingAddress}
                      type="Billing"
                      toggleProceed={toggleProceed}
                      notUseBilling={notUseBilling}
                      toggleNotUseBilling={toggleNotUseBilling}
                      setLoading={(data) => setBillingAddressLoading(data)}
                    />
                  )}
                  <CheckoutSummary
                    totalPriceReceiver={setTotalPrice}
                    modalToggle={toggle}
                    canProceed={canProceed}
                    deliveryAddressLoading={deliveryAddressLoading}
                    billingAddressLoading={billingAddressLoading}
                    notUseBilling={notUseBilling}
                    postalStatus={postalStatus}
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Payment</ModalHeader>
          <ModalBody>
            <div className="form-group mb-0">
              <label>Select payment method:</label>
            </div>
            <div className="custom-control custom-radio mb-2">
              <input
                type="radio"
                className="custom-control-input"
                id="customRadio"
                name="example1"
                onChange={() => setPaymentMethod("Card")}
              />
              <label className="custom-control-label" htmlFor="customRadio">
                Credit / Debit Card
              </label>
            </div>
            <div className="custom-control custom-radio mb-2">
              <input
                type="radio"
                className="custom-control-input"
                id="customRadioInstant"
                name="example1"
                onChange={() => setPaymentMethod("Instant")}
              />
              <label
                className="custom-control-label"
                htmlFor="customRadioInstant"
              >
                Apple Pay / Google Pay via Stripe
              </label>
            </div>
            <div className="custom-control custom-radio mb-2">
              <input
                type="radio"
                className="custom-control-input"
                id="customRadioAlipay"
                name="example1"
                onChange={() => setPaymentMethod("Alipay")}
              />
              <label
                className="custom-control-label"
                htmlFor="customRadioAlipay"
              >
                AliPay
              </label>
            </div>
            <div className="custom-control custom-radio mb-2">
              <input
                type="radio"
                className="custom-control-input"
                id="customRadioWechatpay"
                name="example1"
                onChange={() => setPaymentMethod("Wechatpay")}
              />
              <label
                className="custom-control-label"
                htmlFor="customRadioWechatpay"
              >
                WeChat Pay
              </label>
            </div>
            {paymentMethod === "Card" && (
              <React.Fragment>
                <hr />
                <CheckoutForm
                  setErrorMsg={errorMsgSet}
                  errorMsg={errorMsg}
                  loadingTrigger={loadingStart}
                  isLoading={isLoading}
                  passPayment={handlePaymentFinal}
                  totalPrice={totalPrice}
                />
              </React.Fragment>
            )}
            {paymentMethod === "Instant" && (
              <React.Fragment>
                <hr />
                <CheckoutButton
                  totalPrice={totalPrice}
                  passPayment={handlePaymentFinal}
                  redirect={redirect}
                />
              </React.Fragment>
            )}
            {paymentMethod === "Alipay" && (
              <React.Fragment>
                <hr />
                <AlipayButton totalPrice={totalPrice} />
              </React.Fragment>
            )}
            {paymentMethod === "Wechatpay" && (
              <React.Fragment>
                <hr />
                <WechatpayButton
                  passPayment={handlePaymentFinal}
                  totalPrice={totalPrice}
                />
              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center"></ModalFooter>
        </Modal>
      </section>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  cart: selectCartItems,
  price: selectPrice,
  user: selectCurrentUser,
  validation: selectUserValidation,
  userAddress: selectUserAddress,
  userBillingAddress: selectUserBillingAddress,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  wipeCart: () => dispatch(wipeCart()),
  setUserBillingAddress: (item) => dispatch(setBillingAddress(item)),
  setUserAddress: (item) => dispatch(setAddress(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);

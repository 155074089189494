import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

import CustomButtom from "../components/custom-button.component";
import {
  refreshToken,
  fetchDeliveryAddress,
  fetchBillingAddress,
} from "../functions/user.functions";
import { setToken } from "../redux/user/user.action";
import {
  selectUserToken,
  selectCurrentUser,
} from "../redux/user/user.selector";
import DeliveryAddress from "../components/delivery-address.component";
import BillingAddress from "../components/billing-address.component";

const CheckoutAddress = ({
  type,
  user,
  setToken,
  token,
  receiver,
  className,
  toggleNotUseBilling,
  notUseBilling,
  setLoading,
  setPostalStatus,
}) => {
  const [address, setAddress] = useState(null);
  const [modal, setModal] = React.useState(false);
  const [isLoading, toggleLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await refreshToken(user.uid, setToken);
      if (type === "Delivery") {
        const fetchAddress = await fetchDeliveryAddress(token);
        const data = fetchAddress.data;
        setAddress(data[0]);
        receiver(data[0]);
        toggleLoading(false);
      } else if (type === "Billing") {
        const fetchAddress = await fetchBillingAddress(token);
        const data = fetchAddress.data;
        setAddress(data[0]);
        receiver(data[0]);
        toggleLoading(false);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (type === "Delivery" && address !== null) {
      const checkPostalCode = async () => {
        setLoading(true);
        const token = await refreshToken(user.uid, setToken);
        var myHeaders = new Headers();
        myHeaders.append("token", token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("postal_code", address.postal_code);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        const fetchData = await fetch(
          process.env.REACT_APP_API_URL + "user/postalcode/check",
          requestOptions
        );

        const response = await fetchData.json();
        if (response.status_code === 404) {
          setPostalStatus(false);
        } else {
          setPostalStatus(true);
        }
        setLoading(false);
      };

      checkPostalCode();
    }
  }, [address]);

  const setCheckOutAddress = (data) => {
    setAddress(data);
    receiver(data);
    toggle();
  };

  const toggle = () => setModal(!modal);

  const handleCheck = (e) => {
    toggleNotUseBilling(e.target.checked);
  };

  return (
    <React.Fragment>
      <div className={`card summarry-address-card mb-3 ${className}`}>
        <strong className="card-title">{type} Address</strong>
        {!isLoading ? (
          !address ? (
            <React.Fragment>
              <span className="text-center">
                You haven't set any {type} Adress yet.
              </span>
              <span className="text-center">
                <Link
                  to={`/settings/${
                    type === "Delivery" ? "delivery-address" : "billing-address"
                  }`}
                >
                  <strong className="text-secondary">
                    Click here to add one.
                  </strong>
                </Link>
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="card-content">
                <span className="address-name">{address.name}</span>
                <span className="address-full">
                  {address.address}, {address.postal_code}
                </span>
              </div>
              {type === "Delivery" && (
                <div className="custom-control custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck"
                    name="example1"
                    onChange={handleCheck}
                    checked={notUseBilling}
                  />
                  <label className="custom-control-label" htmlFor="customCheck">
                    Apply this address as billing address
                  </label>
                </div>
              )}
              <CustomButtom onClick={toggle} cart additionalClass="mt-3">
                Change {type} Address
              </CustomButtom>
            </React.Fragment>
          )
        ) : (
          <React.Fragment>
            <div className="card-content">
              <span className="address-name">
                <SkeletonLoader height="30px" borderRadius="20px" />
                <div className="mb-2"></div>
              </span>
              <span className="address-full">
                <SkeletonLoader height="20px" borderRadius="20px" />
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Change {type} Address</ModalHeader>
        <ModalBody>
          {type === "Delivery" && <DeliveryAddress setCheckOutAddress={setCheckOutAddress} mode="select-mode" />}
          {type === "Billing" && <BillingAddress setCheckOutAddress={setCheckOutAddress} mode="select-mode" />}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center"></ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  token: selectUserToken,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddress);

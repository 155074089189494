export const addItemToCart = (currentCartItems, newCartItems) => {
  const existingCart = currentCartItems.find(
    (item) => item.id === newCartItems.id
  );

  if (existingCart) {
    return currentCartItems.map((item) =>
      item.id === newCartItems.id
        ? {
            ...item,
            quantity: item.quantity + 1,
          }
        : item
    );
  }

  return [...currentCartItems, { ...newCartItems, quantity: 1 }];
};

export const removeItemFromCart = (currentCartItems, newCartItems) => {
  return currentCartItems.filter((cart) => cart.id !== newCartItems);
};

export const setPriceFromCart = (currentCartItems) => {
  const itemCount = currentCartItems.reduce((sum, curVal) => {
    return sum + curVal.quantity;
  }, 0);

  if (itemCount === 3) {
    return {
      total: 1000,
      canPay: true,
      canAdd: true,
    };
  } else if (itemCount === 6) {
    return {
      total: 1800,
      canPay: true,
      canAdd: false,
    };
  } else {
    return {
      total: 0,
      canPay: false,
      canAdd: true,
    };
  }
};

export const addOneItem = (currentCartItems, newCartId) => {
  return currentCartItems.map((cartItem) =>
    cartItem.id === newCartId
      ? { ...cartItem, quantity: cartItem.quantity + 1 }
      : cartItem
  );
};

export const removeOneItem = (currentCartItems, newCartId) => {
  return currentCartItems.map((cartItem) =>
    cartItem.id === newCartId
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

const config = {
  apiKey: "AIzaSyAc0ZgDoVO39qTf_WzErfuxJTHlye_jsy8",
  authDomain: "auth.dffruits.com",
  databaseURL: "https://daily-fresh-fruits.firebaseio.com",
  projectId: "daily-fresh-fruits",
  storageBucket: "daily-fresh-fruits.appspot.com",
  messagingSenderId: "985272765226",
  appId: "1:985272765226:web:e1c69427b341294fc2ac53",
  measurementId: "G-4LPDSCSSM9",
};

firebase.initializeApp(config);
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
const providerFb = new firebase.auth.FacebookAuthProvider();
providerFb.setCustomParameters({
  display: "popup",
});
provider.setCustomParameters({ propmt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const signInWithFacebook = () => auth.signInWithPopup(providerFb);

export default firebase;

import React from "react";
import { Row } from "react-bootstrap";

import OrderTable from "../components/order-table.component";

const OrderCompleted = (props) => {
  return (
    <Row className="billing-address-panel">
      <OrderTable type="Completed" />
    </Row>
  );
};
export default OrderCompleted;

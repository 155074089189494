import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TagManager from "react-gtm-module";
import device from "current-device";

import { auth, analytics } from "./firebase/firebase.utils";

import Index from "./pages/index.component";
import About from "./pages/about.component";
import Support from "./pages/support.component";
import Settings from "./pages/settings.component";
import Order from "./pages/order.component";
import Login from "./pages/login.component";
import CheckOut from "./pages/checkout.component";
import ThankYou from "./pages/thankyou.component";
import CartPage from "./pages/cart.component";
import notFoundPage from "./pages/404.component";

import { setAuth, setToken, setUserData } from "./redux/user/user.action";
import {
  selectCurrentUserData,
  selectCurrentUser,
  selectRegisterStatus,
} from "./redux/user/user.selector";
import { fetchStoreStatus } from "./redux/fruits/fruit.action";

import { refreshToken, refreshUserData } from "./functions/user.functions";

import ScrollToTop from "./components/scroll-top.component";
import NavbarMain from "./components/navbar.component";
import Footer from "./components/footer.component";
import ProtectedRoute from "./components/protected-route.component";
import MobileNavbar from "./components/mobile/mobile-navbar.component";
import MobileHeader from "./components/mobile/mobile-header.component";
import NewUserAlert from "./components/new-user-alert.component.jsx";
import AlipayLoading from "./components/alipay-loading.component";

import "./styles/custom.scss";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_KEY,
};

TagManager.initialize(tagManagerArgs);

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

const App = ({
  setAuth,
  setToken,
  setUser,
  registering,
  userData,
  fetchStoreStatus,
}) => {
  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (!registering) {
          await setAuth(user);
          const token = await refreshToken(user.uid, setToken);
          analytics.logEvent("user_logged_in", {
            email: user.email,
            operating_system: device.os,
            device_type: device.type,
          });
          await refreshUserData(token, setUser);
        }
      }
    });
    fetchStoreStatus();
    return unsubscribeFromAuth;
  }, [auth]);

  return (
    <div className="page-wrapper">
      <NavbarMain></NavbarMain>
      <MobileHeader />
      <ScrollToTop />
      <Elements stripe={stripePromise}>
        <Switch>
          <Route exact path="/" component={Index}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route path="/support" component={Support}></Route>
          <ProtectedRoute
            path="/settings"
            component={Settings}
          ></ProtectedRoute>
          <ProtectedRoute path="/order" component={Order}></ProtectedRoute>
          <Route exact path="/login" component={Login}></Route>
          <ProtectedRoute
            path="/checkout"
            component={CheckOut}
          ></ProtectedRoute>
          <ProtectedRoute
            path="/thankyou"
            component={ThankYou}
          ></ProtectedRoute>
          <Route exact path="/cart" component={CartPage}></Route>
          <Route
            exact
            path="/dev/front"
            render={() => {
              window.location =
                "https://www.linkedin.com/in/bintang-kevin-293394170/";
            }}
          ></Route>
          <Route
            exact
            path="/dev/back"
            render={() => {
              window.location = "https://www.linkedin.com/in/mtfiqh/";
            }}
          ></Route>
          <Route exact path="/alipay-process" component={AlipayLoading}></Route>
          <Route component={notFoundPage}></Route>
        </Switch>
      </Elements>
      <Footer></Footer>
      {userData && <NewUserAlert />}
      <MobileNavbar />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (user) => dispatch(setAuth(user)),
  setToken: (token) => dispatch(setToken(token)),
  setUser: (token) => dispatch(setUserData(token)),
  fetchStoreStatus: () => dispatch(fetchStoreStatus()),
});

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  registering: selectRegisterStatus,
  userData: selectCurrentUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ContactBanner from "./contact-banner.component";
const Footer = ({ supportLinks }) => {
  return (
    <footer className="footer">
      <ContactBanner />
      <div className="footer-links-container">
        <Container>
          <Row>
            <Col>
              <ul className="footer-links">
                <li className="footer-link-title">Daily Fresh Fruits</li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="footer-links">
                <li className="footer-link-title">Help and Support</li>
                {supportLinks.map((menu) => (
                  <li key={menu.title}>
                    <Link to={menu.url}>{menu.title}</Link>
                  </li>
                ))}
              </ul>
            </Col>
            <Col className="d-flex flex-column justify-content-center mt-3 mt-md-0">
              <Image
                className="align-self-center mb-3"
                src={require("../assets/logo-footer.png")}
                width="300"
              ></Image>
              <div className="payment-partners d-flex row mx-0 align-items-center justify-content-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://stripe.com/"
                >
                  <Image
                    src={require("../assets/stripe.png")}
                    height="35"
                  ></Image>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://visa.com/"
                >
                  <Image
                    src={require("../assets/visa.png")}
                    height="35"
                  ></Image>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mastercard.com/"
                >
                  <Image src={require("../assets/mc.png")} height="40"></Image>
                </a>
                <div className="break"></div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.apple.com/sg/apple-pay/"
                >
                  <Image
                    src={require("../assets/applepay.png")}
                    height="30"
                  ></Image>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://pay.google.com/"
                >
                  <Image
                    src={require("../assets/gpay.png")}
                    height="30"
                  ></Image>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://intl.alipay.com/"
                >
                  <Image
                    src={require("../assets/alipay.png")}
                    height="30"
                  ></Image>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://pay.weixin.qq.com/index.php/public/wechatpay_en"
                >
                  <Image
                    src={require("../assets/wechatpay.png")}
                    height="30"
                  ></Image>
                </a>
              </div>
              <span className="copyright text-center mt-3">
                &copy; 2020. Daily Fresh Fruits (Beta). All rights reserved.
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  supportLinks: state.supportLinks.supportLinks,
});

export default connect(mapStateToProps)(Footer);

import React from "react";
import { connect } from "react-redux";
import { Carousel, Modal, Badge } from "react-bootstrap";
import { NavigateNextRounded, NavigateBeforeRounded } from "@material-ui/icons";
import { createStructuredSelector } from "reselect";

import { selectFruitSlideModalImages } from "../redux/fruits/fruits.selector";

const FruitImageSlideModal = ({ show, handleClose, images }) => {
  const imageArrayFetch = Object.values(images);
  const imageArray = imageArrayFetch[0];
  return (
    <Modal
      className="fruit-slideshow-modal"
      show={show}
      size="lg"
      centered
      onHide={handleClose}
    >
      <Modal.Header className="d-flex justify-content-center">
        <Badge variant="primary mb-2">Tap Anywhere To Dismiss</Badge>
      </Modal.Header>
      <Modal.Body className="px-0 rounded">
        <Carousel
          indicators={false}
          nextIcon={
            imageArray && imageArray.length !== 1 ? (
              <NavigateNextRounded className="slider-arrow" />
            ) : (
              ""
            )
          }
          prevIcon={
            imageArray && imageArray.length !== 1 ? (
              <NavigateBeforeRounded className="slider-arrow" />
            ) : (
              ""
            )
          }
        >
          {imageArray &&
            imageArray.map((data) => (
              <Carousel.Item>
                <img className="w-100" src={data.image} />
              </Carousel.Item>
            ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  images: selectFruitSlideModalImages,
});

export default connect(mapStateToProps)(FruitImageSlideModal);

import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
  fetchFruits,
  setFruitSlideModalStatus,
} from "../redux/fruits/fruit.action";

import { selectFruitSlideModalStatus } from "../redux/fruits/fruits.selector";

import Banner from "../components/banner.component";
import Featured from "../components/featured.component";
import Collection from "../components/collection.component";
import FruitImageSlideModal from "../components/fruit-image-slide-modal.component";

const Index = ({
  fetchFruits,
  fruitSlideModalStatus,
  setFruitSlideModalStatus,
}) => {
  const handleClose = () => setFruitSlideModalStatus(false);

  useEffect(() => {
    const loadProducts = () => {
      setFruitSlideModalStatus(false);
      fetchFruits();
    };
    loadProducts();
  }, []);

  return (
    <div>
      <Banner></Banner>
      <Featured></Featured>
      <Collection></Collection>
      <FruitImageSlideModal
        show={fruitSlideModalStatus}
        handleClose={handleClose}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  fruitSlideModalStatus: selectFruitSlideModalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFruits: (fruit) => dispatch(fetchFruits(fruit)),
  setFruitSlideModalStatus: (status) =>
    dispatch(setFruitSlideModalStatus(status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Index);

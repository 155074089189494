import React, { useState } from "react";
import { Tooltip, OverlayTrigger, Spinner, Col } from "react-bootstrap";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import { deleteAddress, refreshToken } from "../functions/user.functions";

import CustomButton from "./custom-button.component";

const AddressCard = ({
  data,
  type,
  toggleSelfTrigger,
  setCheckOutAddress,
  selfTrigger,
  mode,
  setToken,
  user,
}) => {
  const [isLoading, toggleLoading] = useState(false);

  function deleteTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Delete Address
      </Tooltip>
    );
  }

  const handleDelete = async (addressId, addressType = type) => {
    toggleLoading(true);
    const token = await refreshToken(user.uid, setToken);

    await deleteAddress(addressId, addressType, token);

    toggleLoading(false);
    toggleSelfTrigger(!selfTrigger);
  };

  return (
    <Col xs={12} md={6} className="px-2 pb-2 d-flex">
      <div className="d-flex flex-column address-card p-3">
        <strong>{data.name}</strong>
        <span>{data.phone_number}</span>
        <div className="mt-3" />
        <span className="font-weight-bold">{data.recipient}</span>
        <span>{data.address}</span>
        <span>{data.postal_code}</span>
        <div className='flex-grow-1'></div>
        <div className="d-flex justify-content-end align-items-center">
          {isLoading ? (
            <React.Fragment>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
              <span className="sr-only">Loading...</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OverlayTrigger placement="top" overlay={deleteTooltip}>
                <DeleteRoundedIcon
                  className="pointer-cursor text-secondary"
                  onClick={() => handleDelete(data.id)}
                />
              </OverlayTrigger>
              {mode === "select-mode" && (
                <CustomButton additionalClass='ml-2' onClick={() => setCheckOutAddress(data)}>
                  Set as {type} Address
                </CustomButton>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </Col>
  );
};

export default AddressCard;

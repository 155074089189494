import FruitActionTypes from "./fruit.types";

const INITIAL_STATE = {
  fruitItems: null,
  isStoreOpen: false,
  fruitSlideModalShow: false,
  fruitSlideImage: [],
};

const fruitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FruitActionTypes.SET_FRUITS:
      return {
        ...state,
        fruitItems: action.payload.item,
      };
    case FruitActionTypes.SET_STORE_STATUS:
      return {
        ...state,
        isStoreOpen: action.payload.item,
      };
    case FruitActionTypes.SET_FRUIT_SLIDE_MODAL_STATUS:
      return {
        ...state,
        fruitSlideModalShow: action.payload,
      };
    case FruitActionTypes.SET_FRUIT_SLIDE_IMAGES:
      return {
        ...state,
        fruitSlideImage: action.payload,
      };
    default:
      return state;
  }
};

export default fruitReducer;

export const getBanner = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const res = await fetch(
    process.env.REACT_APP_API_URL + "banner",
    requestOptions
  );

  const data = await res.json();

  return data.data;
};

import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { ForumRounded } from "@material-ui/icons";
const ContactBanner = ({ modalSupport }) => (
  <div className="contact-banner">
    <Container>
      <Row>
        <Col>
          <div className="contact-title d-flex flex-row align-items-center justify-content-start justify-content-md-center">
            <Image src={require("../assets/wa.png")} height="70"></Image>
            <div className="contact-title d-flex flex-column justify-content-center">
              <div className="ml-2 contact-subtitle">ENQUIRY AND FEEDBACK</div>
              <div className="ml-2 contact-phone">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=6581985399"
                >
                  +65 8198 5399
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col className={`mt-3 mt-md-0 ${modalSupport && "mt-md-3"}`}>
          <div className="contact-title d-flex flex-row align-items-center justify-content-start justify-content-md-center">
            <ForumRounded />
            <div className="contact-title d-flex flex-column justify-content-center">
              <div className="ml-2 contact-subtitle">NEED SUPPORT?</div>
              <div className="ml-2 contact-email">
                <a href="mailto:dailyfreshfruits20@gmail.com">
                  dailyfreshfruits20@gmail.com
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default ContactBanner;

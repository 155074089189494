const CartActionTypes = {
  CART_ADD: "CART_ADD",
  CART_DELETE: "CART_DELETE",
  SET_PRICE: "SET_PRICE",
  WIPE_CART: "WIPE_CART",
  CART_INCREMENT: "CART_INCREMENT",
  CART_DECREMENT: "CART_DECREMENT",
};

export default CartActionTypes;

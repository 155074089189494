import React, { useState, useEffect } from "react";
import { Form, Col, Badge, Row, Container, Image } from "react-bootstrap";
import { ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import {
  setAuth,
  setToken,
  setUserData,
  setPassword,
} from "../redux/user/user.action";
import {
  auth,
  signInWithGoogle,
  signInWithFacebook,
} from "../firebase/firebase.utils";

import {
  forgetPassword,
  convertFromEp,
  refreshToken,
  getUserType,
} from "../functions/user.functions";

import CustomButton from "./custom-button.component";

const ModalSignInBody = ({ toggler, setPassword, setToken }) => {
  const [hidePassword, togglePassword] = useState(true);
  const [password, typePassword] = useState("You Haven't Entered Any Password");
  const [isLoading, toggleLoading] = useState(false);
  const [isLoadingGoogle, toggleLoadingGoogle] = useState(false);
  const [isLoadingFacebook, toggleLoadingFacebook] = useState(false);
  const [email, setEmail] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [forgetPasswordMode, setForgetPasswordMode] = useState(false);

  useEffect(() => {
    if (password === "") {
      typePassword("You Haven't Enterend Any Password");
    }
  }, [password]);

  const handlePasswordChange = (e) => {
    typePassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordToggle = () => {
    togglePassword(!hidePassword);
  };

  const handleGoogleSignUp = async () => {
    toggleLoadingGoogle(true);
    try {
      var userGoogle = await signInWithGoogle();
      toggleLoadingGoogle(false);
      if (toggler) {
        toggler();
      }
    } catch (e) {
      setErrorMsg(e.message);
      toggleLoadingGoogle(false);
      return;
    }

    if (userGoogle) {
      const token = await refreshToken(userGoogle.user.uid, setToken);
      const response = await convertFromEp(token, "ga");
      console.log(response);
    }
  };

  const handleFacebookSignUp = async () => {
    toggleLoadingFacebook(true);
    try {
      var userFacebook = await signInWithFacebook();
      toggleLoadingFacebook(false);
      if (toggler) {
        toggler();
      }
    } catch (e) {
      setErrorMsg(e.message);
      toggleLoadingFacebook(false);
    }

    if (userFacebook) {
      const token = await refreshToken(userFacebook.user.uid, setToken);
      const response = await convertFromEp(token, "fb");
      console.log(response);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    if (email && password) {
      toggleLoading(!isLoading);
      const userType = await getUserType(email);
      if (userType.data.registered_by === "ga") {
        setErrorMsg(
          "Your account registered as Google account, please log in using Google method."
        );
        toggleLoading(false);
        return;
      } else if (userType.data.registered_by === "fb") {
        setErrorMsg(
          "Your account registered as Facebook account, please log in using Facebook method."
        );
        toggleLoading(false);
        return;
      }
      try {
        await auth.signInWithEmailAndPassword(email, password);
        setPassword(password);
        toggler();
      } catch (e) {
        setErrorMsg(e.message);
        toggleLoading(false);
      }
    } else {
      // toggleLoading(!isLoading);
      setErrorMsg("Please fill in all fields.");
    }
  };

  const togglePasswordMode = () => {
    setErrorMsg(null);
    setSuccessMsg(null);
    setForgetPasswordMode(!forgetPasswordMode);
  };

  const handlePasswordReset = async () => {
    if (email) {
      toggleLoading(true);

      const response = await forgetPassword(email);

      toggleLoading(false);
      if (response.status_code === 200) {
        setSuccessMsg(response.message);
      } else {
        setErrorMsg(response.message);
      }
    } else {
      setErrorMsg("Please fill in the email field.");
    }
  };
  return (
    <React.Fragment>
      {forgetPasswordMode ? (
        <Form>
          <ModalBody>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control required onChange={handleEmailChange} />
                <small className="ml-1">
                  A password reset link will be sent to your email.
                </small>
              </Form.Group>
            </Form.Row>
          </ModalBody>
          <ModalFooter className="d-flex flex-column justify-content-center">
            <Badge
              variant="danger"
              className={`${errorMsg ? "" : "d-none"} ml-2`}
            >
              {errorMsg}
            </Badge>
            <Badge
              variant="primary"
              className={`${successMsg ? "" : "d-none"} ml-2`}
            >
              {successMsg}
            </Badge>
            <CustomButton
              submit
              isLoading={isLoading}
              cart
              additionalClass="btn-lg px-5"
              onClick={handlePasswordReset}
            >
              Send Password Reset Link
            </CustomButton>
            <span
              className="text-primary pointer-cursor"
              onClick={togglePasswordMode}
            >
              Back to sign in.
            </span>
          </ModalFooter>
        </Form>
      ) : (
        <Form>
          <ModalBody>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control required onChange={handleEmailChange} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  onChange={handlePasswordChange}
                />
                <small>
                  <span
                    className="text-primary pointer-cursor"
                    onClick={handlePasswordToggle}
                  >
                    Peek Password
                  </span>
                  <Badge
                    variant="primary"
                    className={`${hidePassword ? "d-none" : ""} ml-2`}
                  >
                    {password}
                  </Badge>
                </small>
              </Form.Group>
            </Form.Row>
          </ModalBody>
          <ModalFooter className="d-flex flex-column justify-content-center">
            <Badge
              variant="danger"
              className={`${errorMsg ? "" : "d-none"} ml-2`}
            >
              {errorMsg}
            </Badge>
            <span
              className="text-primary pointer-cursor"
              onClick={togglePasswordMode}
            >
              Forget Password?
            </span>
            <CustomButton
              submit
              isLoading={isLoading}
              cart
              additionalClass="btn-lg px-5"
              onClick={handleSignIn}
            >
              Sign In
            </CustomButton>
            <small className="my-3">or Sign In With</small>
            <Container>
              <Row>
                <Col md={6}>
                  <CustomButton
                    isLoading={isLoadingFacebook}
                    onClick={handleFacebookSignUp}
                    signInVariant="facebook"
                    large
                  >
                    <Image
                      className="mr-2"
                      src={require("../assets/facebook.png")}
                      height="20"
                    ></Image>
                    Facebook
                  </CustomButton>
                </Col>
                <Col md={6}>
                  <CustomButton
                    isLoading={isLoadingGoogle}
                    onClick={handleGoogleSignUp}
                    signInVariant="google"
                    large
                  >
                    <Image
                      className="mr-2"
                      src={require("../assets/google.png")}
                      height="20"
                    ></Image>
                    Google
                  </CustomButton>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </Form>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAuth: (user) => dispatch(setAuth(user)),
  setToken: (token) => dispatch(setToken(token)),
  setUser: (token) => dispatch(setUserData(token)),
  setPassword: (token) => dispatch(setPassword(token)),
});

export default connect(null, mapDispatchToProps)(ModalSignInBody);

import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import NotificationImportantRoundedIcon from "@material-ui/icons/NotificationImportantRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";

import {
  selectCurrentUserData,
  selectUserConfirmValidation,
  selectUserValidation,
} from "../redux/user/user.selector";
import { setValidate, setConfirmValidate } from "../redux/user/user.action";

import AlertList from "./alert-list.component";

const NewUserAlert = ({
  user,
  setValidate,
  confirmValidation,
  setConfirmValidate,
  validation,
  history,
}) => {
  const [alertContainerShow, setAlertContainerShow] = useState(true);
  const [showConfirmValidate, setShowConfirmValidate] = useState(false);
  const [alert, setAlert] = useState(true);
  useEffect(() => {
    if (user.email_verified && user.phone_number && user.address.length !== 0) {
      setValidate(true);
      setShowConfirmValidate(true);
      if (confirmValidation === false) {
        setTimeout(() => {
          setConfirmValidate(true);
          history.push("/");
        }, 3000);
      }
    } else {
      setAlertContainerShow(true);
      setValidate(false);
      setConfirmValidate(false);
      setShowConfirmValidate(false);
    }

    if (confirmValidation === true) {
      setAlertContainerShow(false);
    }
  }, [user, confirmValidation]);

  return (
    user.length !== 0 && (
      <Container
        className={`fixed-bottom new-user-alert-container mb-3 ${
          alert ? "show" : "hide"
        } ${!alertContainerShow ? "d-none" : ""}`}
      >
        <Row>
          <Col>
            <div className="new-user-alert p-3 d-flex flex-column">
              <div
                className={`row mx-0 justify-content-center ${
                  validation ? "text-primary" : "text-secondary"
                } mb-2 alert-title`}
              >
                <div
                  onClick={() => setAlert(!alert)}
                  className="hide-button text-primary pointer-cursor d-block d-md-none"
                >
                  <ExpandMoreRoundedIcon
                    className={`${alert ? "" : "rotate"}`}
                  />
                  {alert ? "Hide" : "Show"}
                </div>
                <NotificationImportantRoundedIcon className="nudge mb-3 mb-md-0" />
                <span className="text-center ml-2 font-weight-bold">
                  {validation
                    ? "Registration Completed, you will be redirected to shop page soon."
                    : "Please fullfill the requirements below to be able to do transactions."}
                </span>
              </div>
              <Row className="mx-0 align-items-center justify-content-center">
                {!validation && (
                  <React.Fragment>
                    <AlertList
                      onClick={() => setAlert(!alert)}
                      url="/settings/account"
                      verified={user.email_verified}
                    >
                      Email Verification
                    </AlertList>
                    <AlertList
                      onClick={() => setAlert(!alert)}
                      url="/settings/account"
                      verified={user.phone_number ? true : false}
                    >
                      Set Phone Number
                    </AlertList>
                    <AlertList
                      onClick={() => setAlert(!alert)}
                      url="/settings/delivery-address"
                      verified={user.address.length === 0 ? false : true}
                    >
                      Set Delivery Address
                    </AlertList>
                  </React.Fragment>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserData,
  confirmValidation: selectUserConfirmValidation,
  validation: selectUserValidation,
});

const mapDispatchToProps = (dispatch) => ({
  setValidate: (item) => dispatch(setValidate(item)),
  setConfirmValidate: (item) => dispatch(setConfirmValidate(item)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewUserAlert)
);

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import supportLinkReducer from "./support-link/support-link-reducer";
import mainNavReducer from "./main-nav/main-nav-reducer";
import fruitReducer from "./fruits/fruits.reducer";
import userReducer from "./user/user.reducer";
import cartAnimReducer from "./cart-anim/cart-anim.reducer";
import cartReducer from "./cart/cart.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cart"],
};

const rootReducer = combineReducers({
  supportLinks: supportLinkReducer,
  nav: mainNavReducer,
  fruits: fruitReducer,
  user: userReducer,
  cartAnim: cartAnimReducer,
  cart: cartReducer,
});

export default persistReducer(persistConfig, rootReducer);

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Link } from "react-router-dom";

import AccountSettings from "../components/account-settings.component";
import BillingAddress from "../components/billing-address.component";
import DeliveryAddress from "../components/delivery-address.component";

class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      settingsMenu: [
        {
          menu: "Account",
          url: "/settings/account",
        },
        {
          menu: "Delivery Address",
          url: "/settings/delivery-address",
        },
        {
          menu: "Billing Address",
          url: "/settings/billing-address",
        },
      ],
    };
  }

  render() {
    const settingsNav = this.state.settingsMenu;
    return (
      <section className="support-page narrow-container">
        <Container>
          <Row className="flex-column">
            <Col>
              <h1 className="header-title">Settings</h1>
              <div className="panel-card flex-column">
                <div className="panel-card-header">
                  <ul className="card-header-nav m-0 d-flex justify-content-around">
                    {settingsNav.map((menu) => (
                      <li
                        key={menu.menu}
                        className={`${
                          menu.url === this.props.location.pathname
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to={menu.url}>{menu.menu}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="panel-card-body">
                  <Route path="/settings/account" component={AccountSettings} />
                  <Route
                    path="/settings/billing-address"
                    component={BillingAddress}
                  />
                  <Route
                    path="/settings/delivery-address"
                    component={DeliveryAddress}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Settings;

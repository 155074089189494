import React, { useEffect, useState } from "react";

import { getBanner } from "../functions/banner.functions";

import { Carousel } from "react-bootstrap";
import { NavigateNextRounded, NavigateBeforeRounded } from "@material-ui/icons";

const Banner = () => {
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    const fetchBanner = async () => {
      const data = await getBanner();
      setBanner(data);
    };

    fetchBanner();
  }, []);
  return (
    <div>
      <section className="banner py-0">
        <Carousel
          indicators={false}
          nextIcon={
            <NavigateNextRounded className="text-secondary slider-arrow" />
          }
          prevIcon={
            <NavigateBeforeRounded className="text-secondary slider-arrow" />
          }
        >
          {banner &&
            banner.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-none d-md-block w-100"
                  src={item.desktop}
                  alt="DFF Slide"
                />
                <img
                  className="d-block d-md-none w-100"
                  src={item.mobile}
                  alt="DFF Slide"
                />
              </Carousel.Item>
            ))}
        </Carousel>
      </section>
    </div>
  );
};

export default Banner;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { selectFruitsRecommended } from "../redux/fruits/fruits.selector";

import FruitCard from "./fruit-card.component";

const MostPopularFruits = ({ fruitsRecommended }) => {
  let shimmer = [];
  for (let i = 0; i <= 1; ++i) {
    shimmer.push(
      <Col xs={6} md={6} key={i} className="mb-4">
        <SkeletonLoader height="250px" borderRadius="20px" />
      </Col>
    );
  }
  return (
    <Col lg={6} className="mt-5 mt-md-0">
      <h1 className="header-title">Most Popular Fruits</h1>
      <Row>
        {fruitsRecommended ? (
          fruitsRecommended.map((props) => (
            <Col xs={6} md={6} key={props.id} className="mb-2">
              <FruitCard {...props} cardType="mostPopular" />
            </Col>
          ))
        ) : (
          <React.Fragment>{shimmer}</React.Fragment>
        )}
      </Row>
    </Col>
  );
};

const mapStateToProps = createStructuredSelector({
  fruitsRecommended: selectFruitsRecommended,
});

export default connect(mapStateToProps)(MostPopularFruits);

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const notFoundPage = ({ history }) => {
  return (
    <section className="thank-you-page">
      <Container className="py-5">
        <Row className="py-5">
          <Col className="justify-content-center align-items-center flex-column d-flex py-5">
            <span className='header-title'>404</span>
            <span className="thank-you-text text-center">
              Sorry the page you looking for are not found
            </span>
            <Link className="text-primary" to="/">
              Home
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default withRouter(notFoundPage);

import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);

export const selectUserToken = createSelector(
  [selectUser],
  (user) => user.token
);

export const selectCurrentUserData = createSelector(
  [selectUser],
  (user) => user.userData
);

export const selectCurrentUserPassword = createSelector(
  [selectUser],
  (user) => user.password
);

export const selectRegisterStatus = createSelector(
  [selectUser],
  (user) => user.registering
);

export const selectUserValidation = createSelector(
  [selectUser],
  (user) => user.validated
);

export const selectUserAddress = createSelector(
  [selectUser],
  (user) => user.selectedAddress
);

export const selectUserBillingAddress = createSelector(
  [selectUser],
  (user) => user.selectedBillingAddress
);

export const selectUserConfirmValidation = createSelector(
  [selectUser],
  (user) => user.confirmedValidated
);

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
  selectCurrentUser,
  selectUserValidation,
} from "../redux/user/user.selector";
import { selectPrice } from "../redux/cart/cart.selector";

import CartList from "../components/cart-list.component";
import CustomButton from "../components/custom-button.component";

const CartPage = ({ user, price, history, validation }) => {
  const handleCheckOut = () => {
    history.push("/checkout");
  };

  return (
    <section className="cart-page">
      <Container>
        <Row>
          <Col>
            <h1 className="header-title">Cart</h1>
            <div className="cart-card p-3 d-flex flex-column">
              <CartList type="page" />
              <div className="total-price d-flex flex-row align-items-center justify-content-center">
                {price.canPay ? (
                  <React.Fragment>
                    Total:
                    <span className="price ml-2">${price.total / 100}</span>
                  </React.Fragment>
                ) : (
                  user && (
                    <span className="text-center mb-3">
                      Must have exact 3 or 6 items on cart to purchase
                    </span>
                  )
                )}
              </div>
              {user ? (
                validation ? (
                  price.canPay && (
                    <CustomButton onClick={handleCheckOut} cart large>
                      Checkout
                    </CustomButton>
                  )
                ) : (
                  <React.Fragment>
                    <span className="smaller text-secondary text-center">
                      Cannot Check Out
                    </span>
                    <span className="text-center mb-3">
                      Please fullfill the requirement before checking out.
                    </span>
                  </React.Fragment>
                )
              ) : (
                <CustomButton
                  onClick={() => {
                    history.push("/login");
                  }}
                  cart
                  large
                >
                  Sign up to check out
                </CustomButton>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  price: selectPrice,
  validation: selectUserValidation,
});

export default connect(mapStateToProps)(CartPage);

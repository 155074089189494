import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import SupportText from "../components/support-text.component";
import { connect } from "react-redux";

class Support extends React.Component {
  render() {
    // console.log(this.props.location);
    const supportLinks = this.props.supportLinks;
    return (
      <section className="support-page">
        <Container>
          <Row>
            <Col md={3} className="d-none d-md-block">
              <div className="support-nav">
                <ul>
                  {supportLinks.map((menu) => (
                    <li key={menu.title}>
                      <Link
                        className={
                          menu.url === this.props.location.pathname
                            ? "active"
                            : ""
                        }
                        to={menu.url}
                      >
                        {menu.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={9} xs={12}>
              {supportLinks.map((menu) => (
                <Route
                  exact
                  key={menu.title}
                  path={menu.url}
                  render={() => <SupportText title={menu.title} />}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  supportLinks: state.supportLinks.supportLinks,
});

export default connect(mapStateToProps)(Support);

import React, { useEffect } from "react";
import { Container, Row, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";

import { selectCurrentUserData } from "../../redux/user/user.selector";
import { selectNavMenu } from "../../redux/main-nav/main-nav.selector";
import { userLogOut } from "../../redux/user/user.action";

const MobileMenu = ({ user, menu, nav, logOut, toggler }) => {
  const location = useLocation();
  const handleLogOut = async () => {
    await auth.signOut();
    await logOut();
    toggler();
    window.location.reload();
  };

  useEffect(() => {
    toggler(false);
  }, [location]);

  return (
    <div className={`mobile-menu ${menu ? "show" : "hide"}`}>
      <Container fluid>
        <Row className="flex-column justify-content-center align-items-center py-5">
          {user ? (
            <React.Fragment>
              <Image
                src={user.avatar}
                width="150"
                className="rounded-circle mb-3"
              ></Image>
              <span className="user-name">{user.name}</span>
              <span className="user-email">{user.email}</span>
              <Link to="/" onClick={handleLogOut}>
                Logout
              </Link>
            </React.Fragment>
          ) : (
            <span className="menu-item mb-3">
              <Link onClick={() => toggler()} to="/login">
                Sign Up
              </Link>
            </span>
          )}
          <div className="mt-5"></div>
          {nav.map((item, index) => (
            <span key={index} className="menu-item mb-3">
              <Link onClick={() => toggler()} to={item.url}>
                {item.menu}
              </Link>
            </span>
          ))}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUserData,
  nav: selectNavMenu,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(userLogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Image,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { ExitToAppRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import { auth } from "../firebase/firebase.utils";
import { userLogOut } from "../redux/user/user.action";
import { createStructuredSelector } from "reselect";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { selectNavMenu } from "../redux/main-nav/main-nav.selector";
import {
  selectCurrentUser,
  selectCurrentUserData,
} from "../redux/user/user.selector";

import CartIcon from "./cart-icon.component";
import ProfilePicture from "./profile-picture.component";
import Cart from "./cart.component";
import CustomButton from "./custom-button.component";
import ModalSignUpBody from "./modal-sign-up.component";
import ModalSignInBody from "./modal-sign-in.component";
// import SearchBar from "./search-bar.component";

const NavbarMain = ({ menuItems, currentUser, logOut, userData }) => {
  const [cartVisible, setCartVisible] = useState(false);
  const [singUpVisible, setSignUpVisible] = useState(false);
  const [singInVisible, setSignInVisible] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  useEffect(() => {
    setDropdown(false);
  }, [location]);

  const toggleSignUp = () => {
    setSignUpVisible(!singUpVisible);
  };

  const toggleSignIn = () => {
    setSignInVisible(!singInVisible);
  };

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  const signInFromSignUp = () => {
    toggleSignUp();
    toggleSignIn();
  };

  const handleLogOut = async () => {
    await auth.signOut();
    await logOut();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={singUpVisible} toggle={toggleSignUp}>
        <ModalHeader toggle={toggleSignUp}>
          <span className="header-title">Sign Up</span>
          <small>
            Already have an account?
            <strong onClick={signInFromSignUp}> Sign In here.</strong>
          </small>
        </ModalHeader>
        <ModalSignUpBody toggler={toggleSignUp} />
      </Modal>
      <Modal size="lg" isOpen={singInVisible} toggle={toggleSignIn}>
        <ModalHeader toggle={toggleSignIn}>
          <span className="header-title">Sign In</span>
        </ModalHeader>
        <ModalSignInBody toggler={toggleSignIn} />
      </Modal>
      <Navbar className="d-none d-md-flex" fixed="top">
        <Container>
          <Navbar.Brand to="#home">
            <Link to="/">
              <Image src={require("../assets/logo.png")} width="200"></Image>
            </Link>
          </Navbar.Brand>
          <Nav className="mr-auto">
            {menuItems.map((menu) => (
              <Link key={menu.menu} className="nav-link" to={menu.url}>
                {menu.menu}
              </Link>
            ))}
          </Nav>
          {/*<SearchBar />*/}
          <CartIcon onClick={toggleCart} marginX />
          <div className="limiter" />
          {currentUser ? (
            <Dropdown onClick={toggleDropdown} show={dropdown} alignRight>
              <Dropdown.Toggle
                className="profile-dropdown"
                variant="transparent"
                id="dropdown-basic"
              >
                {userData ? (
                  <React.Fragment>
                    <ProfilePicture src={userData.avatar} width="45" />
                    <span className="ml-2">{userData.name}</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                    <span className="sr-only">Loading...</span>
                  </React.Fragment>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-center">
                {userData ? (
                  <h4 className="dropdown-header">
                    {userData.name} <br /> <small>{userData.email}</small>
                  </h4>
                ) : (
                  <SkeletonLoader height="20px" borderRadius="20px" />
                )}

                <Dropdown.Divider />
                <Link
                  to="/order/procesed"
                  role="button"
                  className="dropdown-item"
                >
                  Order Lists
                </Link>
                <Link
                  to="/settings/account"
                  role="button"
                  className="dropdown-item"
                >
                  Settings
                </Link>
                <Link
                  to="/"
                  onClick={handleLogOut}
                  role="button"
                  className="dropdown-item"
                >
                  <ExitToAppRounded /> &nbsp; Log Out
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div className="sign-in-sign-up-group">
              <CustomButton onClick={toggleSignUp}>Sign Up</CustomButton>
              <CustomButton onClick={toggleSignIn} white>
                Sign In
              </CustomButton>
            </div>
          )}
        </Container>
        <Cart handleMouseDown={toggleCart} cartVisibility={cartVisible} />
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  menuItems: selectNavMenu,
  currentUser: selectCurrentUser,
  userData: selectCurrentUserData,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(userLogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarMain);

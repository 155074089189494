import React, { useState } from "react";
import { Row, Form, Col } from "react-bootstrap";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectUserToken,
  selectCurrentUser,
  selectCurrentUserData,
} from "../redux/user/user.selector";
import { setToken } from "../redux/user/user.action";

import { addAddress } from "../functions/user.functions";

import CustomButton from "../components/custom-button.component";
import AddressTable from "../components/address-table.component";

const DeliveryAddress = ({ user, uid, setToken, mode, setCheckOutAddress }) => {
  const [recipient, setRecipient] = useState(null);
  const [title, setTitle] = useState(null);
  const [phone, setPhone] = useState(null);
  const [postal, setPostal] = useState(null);
  const [address, setAddress] = useState(null);
  const [refreshTrigger, toggleRefreshTrigger] = useState(true);
  const [isLoading, toggleLoading] = useState(false);

  const handleRecipientField = (e) => {
    setRecipient(e.target.value);
  };

  const handleTitleField = (e) => {
    setTitle(e.target.value);
  };

  const handlePhoneField = (e) => {
    setPhone(e.target.value);
  };

  const handlePostalField = (e) => {
    setPostal(e.target.value);
  };

  const handleAddressField = (e) => {
    setAddress(e.target.value);
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    toggleLoading(true);

    await addAddress(
      uid.uid,
      setToken,
      title,
      address,
      postal,
      recipient,
      phone
    );

    toggleLoading(false);
    toggle();
    toggleRefreshTrigger(!refreshTrigger);
  };

  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Row
      className={`billing-address-panel ${
        mode === "select-mode" ? "py-0 px-3" : ""
      } flex-column`}
    >
      <div className="button-container mb-4">
        <div className="modal-trigger" onClick={toggle}>
          <CustomButton cart additionalClass={user.address.length === 0 ? 'profile-alert address-alert' : ''}>
            <AddRoundedIcon />
            Add New Delivery Address
          </CustomButton>
        </div>
      </div>
      <AddressTable
        setCheckOutAddress={setCheckOutAddress}
        mode={mode}
        trigger={refreshTrigger}
        type="Delivery"
      />

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={handleAddAddress}>
          <ModalHeader toggle={toggle}>Add Delivery Address</ModalHeader>
          <ModalBody>
            <Form.Group>
              <Form.Label>Save As</Form.Label>
              <Form.Control
                required
                onChange={handleTitleField}
                placeholder="Eg. 'Home', 'Office'
              "
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Recipient Name</Form.Label>
                <Form.Control required onChange={handleRecipientField} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control onChange={handlePhoneField} required />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control required onChange={handlePostalField} />
              </Form.Group>
            </Form.Row>

            <Form.Group>
              <Form.Label>Full Address</Form.Label>
              <Form.Control
                required
                onChange={handleAddressField}
                as="textarea"
                placeholder="Apartment, studio, or floor"
              />
            </Form.Group>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <CustomButton isLoading={isLoading} submit cart>
              Add Delivery Address
            </CustomButton>
          </ModalFooter>
        </Form>
      </Modal>
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserToken,
  uid: selectCurrentUser,
  user: selectCurrentUserData,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress);

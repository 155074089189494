import React from "react";
import { Container, Row, Image, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ThankYou = ({ history }) => {
  return (
    <section className="thank-you-page">
      <Container>
        <Row>
          <Col className="justify-content-center align-items-center flex-column d-flex">
            <Image
              className="img-fluid"
              src={require("../assets/thankyou.png")}
              width="400"
            ></Image>
            <span className="thank-you-text text-center">
              Thank you for shopping in our site, you may go to{" "}
              <Link className="text-primary" to="/order/procesed">
                Order Page
              </Link>{" "}
              to track your order status.
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ThankYou;

import React, { useState, useEffect } from "react";
import { Form, Col, Badge, Row, Container, Image } from "react-bootstrap";
import { ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import {
  auth,
  signInWithGoogle,
  signInWithFacebook,
} from "../firebase/firebase.utils";
import {
  setPassword,
  toggleRegisterStatus,
  setAuth,
  setToken,
  setUserData,
} from "../redux/user/user.action";

import {
  registerUser,
  newUserAuth,
  refreshToken,
  convertFromEp,
} from "../functions/user.functions";

import CustomButton from "./custom-button.component";

const ModalSignUpBody = ({
  toggler,
  toggleRegisterStatus,
  setPassword,
  setAuth,
  setToken,
  setUser,
}) => {
  const [hidePassword, togglePassword] = useState(true);
  const [password, typePassword] = useState("You Haven't Entered Any Password");
  const [isLoading, toggleLoading] = useState(false);
  const [isLoadingGoogle, toggleLoadingGoogle] = useState(false);
  const [isLoadingFacebook, toggleLoadingFacebook] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (password === "") {
      typePassword("You Haven't Enterend Any Password");
    }
  }, [password]);

  const handlePasswordChange = (e) => {
    typePassword(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordToggle = () => {
    togglePassword(!hidePassword);
  };

  const handleGoogleSignUp = async () => {
    setErrorMsg("");
    await toggleRegisterStatus(true);

    toggleLoadingGoogle(true);
    try {
      var user = await signInWithGoogle();
    } catch (e) {
      setErrorMsg(e.message);
      toggleLoadingGoogle(false);
      await toggleRegisterStatus(false);
      return;
    }
    if (user) {
      const token = await refreshToken(user.user.uid, setToken);
      const response = await convertFromEp(token, "ga");
      console.log(response);
    }
    try {
      await newUserAuth(
        user.user,
        user.user.displayName,
        "ga",
        null,
        setPassword,
        setAuth,
        setToken,
        setUser
      );
    } catch (e) {
      console.log(e);
      if (e.response.status !== 400) {
        setErrorMsg(e.response.data.message);
        toggleLoadingGoogle(false);
        await toggleRegisterStatus(false);
        return;
      } else {
        toggleLoadingGoogle(false);
        if (toggler) {
          toggler();
        }
        await toggleRegisterStatus(false);
      }
    }

    toggleLoadingGoogle(false);
    if (toggler) {
      toggler();
    }
    await toggleRegisterStatus(false);
  };

  const handleFacebookSignUp = async () => {
    setErrorMsg("");
    await toggleRegisterStatus(true);

    toggleLoadingFacebook(true);
    try {
      var user = await signInWithFacebook();
    } catch (e) {
      setErrorMsg(e.message);
      toggleLoadingFacebook(false);
      await toggleRegisterStatus(false);
      return;
    }
    if (user) {
      const token = await refreshToken(user.user.uid, setToken);
      const response = await convertFromEp(token, "fb");
      console.log(response);
    }
    try {
      await registerUser(user.user.uid, user.user.displayName, "fb");
      await newUserAuth(
        user.user,
        user.user.displayName,
        "fb",
        null,
        setPassword,
        setAuth,
        setToken,
        setUser
      );
    } catch (e) {
      if (e.response.status !== 400) {
        setErrorMsg(e.response.data.message);
        toggleLoadingFacebook(false);
        await toggleRegisterStatus(false);
        return;
      } else {
        toggleLoadingFacebook(false);
        if (toggler) {
          toggler();
        }
        await toggleRegisterStatus(false);
      }
    }

    toggleLoadingFacebook(false);
    if (toggler) {
      toggler();
    }
    await toggleRegisterStatus(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    await toggleRegisterStatus(true);

    if (name && email && password) {
      toggleLoading(!isLoading);
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        // console.log(user);
        await newUserAuth(
          user,
          name,
          "ep",
          password,
          setPassword,
          setAuth,
          setToken,
          setUser
        );
        toggler();
      } catch (e) {
        setErrorMsg(e.message);
        toggleLoading(false);
      }
    } else {
      // toggleLoading(!isLoading);
      setErrorMsg("Please fill in all fields.");
    }
    await toggleRegisterStatus(false);
  };

  return (
    <React.Fragment>
      <Form>
        <ModalBody>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Name</Form.Label>
              <Form.Control required onChange={handleNameChange} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control required onChange={handleEmailChange} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                onChange={handlePasswordChange}
              />
              <small>
                <span
                  className="text-primary pointer-cursor"
                  onClick={handlePasswordToggle}
                >
                  Peek Password
                </span>
                <Badge
                  variant="primary"
                  className={`${hidePassword ? "d-none" : ""} ml-2`}
                >
                  {password}
                </Badge>
              </small>
            </Form.Group>
          </Form.Row>
        </ModalBody>
        <ModalFooter className="d-flex flex-column justify-content-center">
          <Badge
            variant="danger"
            className={`${errorMsg ? "" : "d-none"} ml-2`}
          >
            {errorMsg}
          </Badge>
          <CustomButton
            submit
            onClick={handleSignUp}
            isLoading={isLoading}
            cart
            additionalClass="btn-lg px-5"
          >
            Sign Up
          </CustomButton>
          <small className="my-3">or Sign Up With</small>
          <Container>
            <Row>
              <Col md={6}>
                <CustomButton
                  isLoading={isLoadingFacebook}
                  onClick={handleFacebookSignUp}
                  signInVariant="facebook"
                  large
                >
                  <Image
                    className="mr-2"
                    src={require("../assets/facebook.png")}
                    height="20"
                  ></Image>
                  Facebook
                </CustomButton>
              </Col>
              <Col md={6}>
                <CustomButton
                  isLoading={isLoadingGoogle}
                  onClick={handleGoogleSignUp}
                  signInVariant="google"
                  large
                >
                  <Image
                    className="mr-2"
                    src={require("../assets/google.png")}
                    height="20"
                  ></Image>
                  Google
                </CustomButton>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Form>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPassword: (token) => dispatch(setPassword(token)),
  toggleRegisterStatus: (status) => dispatch(toggleRegisterStatus(status)),
  setAuth: (user) => dispatch(setAuth(user)),
  setToken: (token) => dispatch(setToken(token)),
  setUser: (token) => dispatch(setUserData(token)),
});

export default connect(null, mapDispatchToProps)(ModalSignUpBody);

import React from "react";

import { Container, Row } from "react-bootstrap";

import FruitOfTheDay from "./fruits-of-the-day.component";
import MostPopularFruits from "./most-popular-fruits.component";

const Featured = () => (
  <section className="featured-products">
    <Container>
      <Row>
        <FruitOfTheDay />
        <MostPopularFruits />
      </Row>
    </Container>
  </section>
);

export default Featured;

import React from "react";
import { Button, Spinner } from "react-bootstrap";

const CustomButton = ({
  signInVariant,
  isLoading,
  additionalClass,
  submit,
  ...props
}) => {
  var variant;
  var large;
  var outline;
  if (props.cart) {
    variant = "secondary";
  } else {
    variant = "primary";
  }

  if (signInVariant) {
    variant = signInVariant;
  }

  if (props.white) {
    outline = "outline-";
  }

  if (props.large) {
    large = "btn-block";
  }

  return (
    <Button
      type={submit ? "submit" : ""}
      disabled={isLoading ? true : false}
      onClick={props.onClick ? props.onClick : undefined}
      variant={`${outline ? "outline-" : ""}${variant}`}
      className={`custom-button ${large} ${
        additionalClass ? additionalClass : ""
      }`}
    >
      {isLoading ? (
        <React.Fragment>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          <span className="sr-only">Loading...</span>
        </React.Fragment>
      ) : (
        ""
      )}
      {props.children}
    </Button>
  );
};

export default CustomButton;

import FruitActionTypes from "./fruit.types";

export const setFruits = (item) => ({
  type: FruitActionTypes.SET_FRUITS,
  payload: { item },
});

export const setStoreStatus = (item) => ({
  type: FruitActionTypes.SET_STORE_STATUS,
  payload: { item },
});

export const fetchFruits = () => {
  return async (dispatch) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "product");
    const resJson = await response.json();
    dispatch(setFruits(resJson.data));
  };
};

export const fetchStoreStatus = () => {
  return async (dispatch) => {
    const fetchedData = await fetch(process.env.REACT_APP_API_URL + "store");

    const response = await fetchedData.json();
    dispatch(setStoreStatus(response.data.open === 1 ? true : false));
  };
};

export const setFruitSlideModalStatus = (status) => ({
  type: FruitActionTypes.SET_FRUIT_SLIDE_MODAL_STATUS,
  payload: status,
});

export const setFruitSlideModalImages = (images) => ({
  type: FruitActionTypes.SET_FRUIT_SLIDE_IMAGES,
  payload: { images },
});

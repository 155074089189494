import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SkeletonLoader from "tiny-skeleton-loader-react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectFruitsItems } from "../redux/fruits/fruits.selector";

import FruitCard from "./fruit-card.component";

const Collection = ({ fruits }) => {
  let shimmer = [];
  for (let i = 0; i <= 3; ++i) {
    shimmer.push(
      <Col xs={6} md={3} key={i} className="mb-4">
        <SkeletonLoader
          height="250px"
          borderRadius="20px"
          background="#ade0c7"
        />
      </Col>
    );
  }

  return (
    <section className="collection mt-5">
      <Container>
        <Row className="d-flex justify-content-center">
          <h1 className="header-title">Available Fruits</h1>
        </Row>
        <Row className="justify-content-center">
          {fruits ? (
            fruits.map((props) => (
              <Col xs={6} md={3} key={props.id} className="mb-4 d-flex">
                <FruitCard {...props} cardType="collection" />
              </Col>
            ))
          ) : (
            <React.Fragment>{shimmer}</React.Fragment>
          )}
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  fruits: selectFruitsItems,
});

export default connect(mapStateToProps)(Collection);

import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const AlertList = ({ children, url, verified, onClick }) => {
  return (
    <Col xs={12} md={4} onClick={onClick} className="p-2">
      <Link to={url}>
        <div
          className={`alert-list align-items-center d-flex flex-row justify-content-start ${
            verified ? "" : "unverified"
          }`}
        >
          <div className="check-round p-2">
            {verified ? <CheckRoundedIcon /> : <CloseRoundedIcon />}
          </div>
          <div className="d-flex flex-column justify-content-center">
            <span className="font-weight-bold">{children}</span>
            {!verified && <span className="smaller">Click Here</span>}
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default AlertList;
